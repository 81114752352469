.centersAndDepartmentsTab {
    &__header {
        display: flex;
        justify-content: space-between;
    }
    &__search {
        display: flex;
        max-width: 250px;
        align-self: flex-end;
        height: 42px;
        align-items: center;
    }
}
