@use "/src/styles/colors.scss" as c;

@mixin disabledBadgeIcon() {
    color: c.$gray600 !important;
}

.badgeIcon {
    font-size: 12px;

    &--blue {
        @extend .badgeIcon;
        &.light {
            color: c.$primary500;
        }
        &.dark {
            color: c.$primary100;
        }
    }
    &--grey {
        @extend .badgeIcon;
        &.light {
            color: c.$gray500;
        }
        &.dark {
            color: c.$baseWhite;
        }
    }
    &--red {
        @extend .badgeIcon;
        &.light {
            color: c.$error500;
        }
        &.dark {
            color: c.$error100;
        }
    }
    &--orange {
        @extend .badgeIcon;
        &.light {
            color: c.$warning500;
        }
        &.dark {
            color: c.$warning100;
        }
    }
    &--green {
        @extend .badgeIcon;
        &.light {
            color: c.$success500;
        }
        &.dark {
            color: c.$success100;
        }
    }
    &--yellow {
        @extend .badgeIcon;
        &.light {
            color: c.$yellow600;
        }
        &.dark {
            color: c.$yellow100;
        }
    }
    &--teal {
        @extend .badgeIcon;
        &.light {
            color: c.$teal600;
        }
        &.dark {
            color: c.$teal100;
        }
    }
    &--disabled {
        @extend .badgeIcon;
        @include disabledBadgeIcon();
    }
}

.badgeIcon--outline {
    @extend .badgeIcon;

    &--blue {
        @extend .badgeIcon;
        color: c.$primary600;
    }
    &--grey {
        @extend .badgeIcon;
        color: c.$gray600;
    }
    &--red {
        @extend .badgeIcon;
        color: c.$error600;
    }
    &--orange {
        @extend .badgeIcon;
        color: c.$orangeDark600;
    }
    &--green {
        @extend .badgeIcon;
        color: c.$success600;
    }
    &--yellow {
        @extend .badgeIcon;
        color: c.$yellow600;
    }
    &--teal {
        @extend .badgeIcon;
        color: c.$teal600;
    }
}
