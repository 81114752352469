@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.rankingLayoutHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 12px;
    }
    &__title {
        color: c.$baseWhite;
        @include t.text("xl", "semibold");
        position: relative;
        &__container {
            color: c.$baseWhite;
        }
        &__dropdown {
            position: absolute;
            border: 1px solid c.$gray300;
            padding: 6px;
            background-color: c.$baseWhite;
            z-index: 5;
            border-radius: 6px;
            box-shadow: c.$shadow-lg;
        }
    }
    &__filters {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 12px;
        justify-content: flex-end;
        &--column {
            @extend .rankingLayoutHeader__filters;
            flex-direction: column;
            width: 100%;
        }
        &__input {
            width: 100%;
        }
        &__icon {
            width: 20px;
            height: 20px;
            color: c.$baseWhite;
            cursor: pointer;
        }
    }
    &__sort {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 0px;
        gap: 20px;
        position: relative;
        &__order {
            display: flex;
            align-items: center;
            gap: 12px;
            justify-content: flex-end;
            cursor: pointer;
            height: 100%;
            flex-shrink: 0;
            border-radius: 4px;
            padding: 4px;
            &:hover {
                background-color: c.$iconHoverBg;
            }
            &__text {
                color: c.$baseWhite;
                user-select: none;
                @include t.text("xs", "medium");
            }
            &__container {
                display: flex;
                gap: 2px;
            }
        }
        &__secondaryFilter {
            display: flex;
            padding: 0 0 0 12px;
            align-items: flex-start;
            color: c.$baseWhite;
            cursor: pointer;
            user-select: none;
            &__icon {
                width: 16px;
                height: 16px;
            }
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .rankingLayoutHeader {
        &__filters {
            width: 515px;
        }
    }
}
@media screen and (max-width: v.$mobile) {
    .rankingLayoutHeader {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }
}
