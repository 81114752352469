@use "/src/styles/colors.scss" as c;

.legendItemMark {
    width: 12px;
    height: 12px;
    &--lightRed {
        @extend .legendItemMark;
        background-color: c.$error300;
    }
    &--red {
        @extend .legendItemMark;
        background-color: c.$error500;
    }
    &--lightDarkRed {
        @extend .legendItemMark;
        background-color: c.$error700;
    }
    &--darkRed {
        @extend .legendItemMark;
        background-color: c.$error800;
    }
    &--lightYellow {
        @extend .legendItemMark;
        background-color: c.$yellow200;
    }
    &--yellow {
        @extend .legendItemMark;
        background-color: c.$yellow400;
    }
    &--lightGreen {
        @extend .legendItemMark;
        background-color: c.$success200;
    }
    &--green {
        @extend .legendItemMark;
        background-color: c.$success300;
    }
    &--lightBlue {
        @extend .legendItemMark;
        background-color: c.$primary300;
    }
    &--blue {
        @extend .legendItemMark;
        background-color: c.$primary600;
    }
    &--lightBlue {
        @extend .legendItemMark;
        background-color: c.$primary300;
    }
    &--gray {
        @extend .legendItemMark;
        background-color: c.$gray300;
    }
}
