@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.legendItem {
    display: flex;
    height: 32px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 2px;
    &__info {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
    }
    &__text {
        color: c.$gray700;
        @include t.text("sm", "medium");
    }
    &__percentatge {
        color: c.$gray700;
        width: 65px;
        @include t.text("lg", "semibold");
    }

    &--clickable {
        cursor: pointer;
        &--lightRed:hover,
        &--red:hover {
            background-color: c.$error50;
            border-color: c.$error600;
        }
        &--lightDarkRed:hover,
        &--darkRed:hover {
            background-color: c.$error100;
            border-color: c.$error800;
        }
        &--lightYellow:hover,
        &--yellow:hover {
            background-color: c.$yellow50;
            border-color: c.$yellow500;
        }
        &--lightGreen:hover,
        &--green:hover {
            background-color: c.$success50;
            border-color: c.$success500;
        }
        &--lightBlue:hover,
        &--blue:hover {
            background-color: c.$primary50;
            border-color: c.$primary500;
        }
        &--lightGray:hover,
        &--gray:hover {
            background-color: c.$gray50;
            border-color: c.$gray500;
        }
    }
}
