@use "/src/styles/variables.scss" as v;

.documentalFileModal {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (min-width: v.$tablet) {
    .documentalFileModal {
        gap: 32px;
    }
}
