@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.documentalFileModalHeader {
    &__imagePreview {
        display: flex;
        height: 400px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid c.$gray300;
        position: relative;
        border-radius: 8px;
        border: 1px solid var(--Gray-300, #d6d6d6);
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }
    &__zoom {
        display: flex;
        height: 44px;
        padding: 6.286px;
        justify-content: center;
        align-items: center;
        gap: 15x;
        position: absolute;
        left: 20px;
        bottom: 20px;
        border-radius: 6.286px;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(6.285714149475098px);
        cursor: pointer;
        &__icon {
            display: flex;
            width: 31px;
            height: 31px;
            justify-content: center;
            align-items: center;
            color: c.$baseWhite;
        }
    }

    &__audio {
        display: flex;
        padding: 15px 14px;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: 200px;
        border: 1px solid c.$gray300;
        background: c.$gray100;
        &__content {
            width: 100%;
        }
    }
}
