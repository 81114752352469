@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.auditChecklistItem {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 12px 8px;
    border-bottom: 1px solid c.$gray300;
    &:nth-last-child(1) {
        border: none;
    }
    &__header {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        width: 100%;
    }
    &__title {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
    }
    &__name {
        @include t.text("sm");
        color: c.$gray800;
        width: 100%;
    }
    &__required {
        @extend .auditChecklistItem__name;
        color: c.$danger;
    }
    &__checkboxes {
        display: flex;
        gap: 8px;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        max-width: 400px;
    }
}
@media screen and (min-width: v.$mobile) {
    .auditChecklistItem {
        gap: 16px;
        padding: 14px 8px;
        &__header {
            flex-direction: row;
            gap: 32px;
        }

        &__checkboxes {
            width: fit-content;
        }
    }
}
