.auditGroupChecklist {
    &__name {
        min-width: 250px;
    }
    &__total {
        max-width: 230px;
        width: 230px;
    }
    &__progressbar {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;
    }
    &__status {
        width: 15%;
        min-width: 150px;
        &__container {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
    &__comments {
        width: 120px;
        max-width: 120px;
    }
    &__badgeComment {
        display: flex;
        justify-content: center;
    }
    &__actions {
        width: 10%;
        min-width: 100px;
    }
}
