@use "/src/styles/colors.scss" as c;

.popover {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: c.$baseWhite;
    border-radius: 10px;
    box-shadow: c.$shadow-2xl;
    overflow: hidden;
    left: 0;
    top: 0;
    animation: slideIn 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translate(var(--tx), var(--ty)) scale(0.6);
    }
    100% {
        opacity: 1;
        transform: translate(var(--tx), var(--ty)) scale(1);
    }
}
