@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.planAPPCCCounters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;

    &__title {
        color: c.$primary700;
        @include t.text("sm", "semibold");
        line-height: 0px;
    }
    &__icon {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        &__svg {
            color: c.$primary700;
            text-align: center;
            font-size: 16px;
        }
    }
    &:hover {
        .planAPPCCCounters__title {
            color: c.$primary800;
        }
        .planAPPCCCounters__icon {
            &__svg {
                color: c.$primary800;
            }
        }
    }

    &--readonly {
        @extend .planAPPCCCounters;
        cursor: default;

        &:hover {
            .planAPPCCCounters__title {
                color: c.$primary700;
            }
            .planAPPCCCounters__icon {
                &__svg {
                    color: c.$primary700;
                }
            }
        }
    }
}
