@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.auditGroupCheckListModalActions {
    display: flex;
    padding: 16px 8px;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    border-bottom: 1px solid c.$gray300;
    &__button {
        justify-self: flex-end;
        flex: 1;
    }
    &__checkboxes {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-direction: column;
        &__container {
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }
    &__checkAll {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        color: c.$gray500;
        text-overflow: ellipsis;
        @include t.text("xs", "regular");
    }
    &__comment {
        display: none;
    }
}

@media screen and (min-width: v.$mobile) {
    .auditGroupCheckListModalActions {
        &__checkboxes {
            flex-direction: row;
        }
        &__comment {
            width: 28px;
            display: block;
        }
    }
}
