@use "/src/styles/colors.scss" as c;

.documentalMoreOptions {
    display: flex;
    width: 28px;
    padding: 4px;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 4px;
    top: 4px;
    border-radius: 4px;
    &:hover {
        background-color: c.$gray200;
    }
    &__svg {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
    &__container {
        min-width: 150px;
    }
}
