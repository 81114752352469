@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.documentalFile {
    --documentalFileUrl: "";
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    cursor: pointer;
    position: relative;

    &__card {
        display: flex;
        height: 150px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        aspect-ratio: 1/1;
        border-radius: 8px;
        border: 1px solid c.$gray300;
        background: c.$baseWhite;
        &__image {
            display: block;
            gap: 12px;
            align-self: stretch;
            aspect-ratio: 1 / 1;
            object-fit: cover;
            border-radius: 8px;
            height: 100%;
            width: auto;
        }
    }
    &:hover {
        .documentalFile__name {
            text-decoration: underline;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        width: 100%;
    }
    &__name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        align-self: stretch;
        overflow: hidden;
        color: c.$primary600;
        text-overflow: ellipsis;
        @include t.text("sm", "semibold");
    }
    &__info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
    &__date,
    &__type {
        color: c.$gray500;
        @include t.text("xs", "medium");
    }
    &__divider {
        color: c.$gray300;
    }
}
