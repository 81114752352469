@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.multiProgressBar {
    display: flex;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
    &__item {
        display: flex;
        flex-direction: column;
        gap: 2px;
        min-width: 40px;
        &:first-child .multiProgressBar__item__bar {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
        &:last-child .multiProgressBar__item__bar {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
        &__value {
            height: 18px;
            color: c.$gray700;
            text-align: right;
            width: 100%;
            @include t.text("xs", "regular");
        }
        &__bar {
            height: 8px;
            &--red {
                background-color: c.$error500;
            }
            &--yellow {
                background-color: c.$yellow400;
            }
            &--green {
                background-color: c.$success300;
            }
            &--gray {
                background-color: c.$gray300;
            }
        }
    }
}
