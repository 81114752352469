@use "/src/styles/variables.scss" as v;

.documentalFileModalBody {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        flex: 1 0 0;
    }
    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        width: 100%;
    }
    &__avatar {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
    }
}

@media screen and (min-width: v.$tablet) {
    .documentalFileModalBody {
        flex-direction: row;
    }
}
