@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;
@use "/src/styles/variables.scss" as v;

.documentalFileSkelleton {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 12px;
    width: 100%;
    &__file {
        cursor: default;
        height: 206px;
        &:hover {
            background-color: none !important;
        }
    }
    &__card {
        width: 176px;
    }
    &__icon {
        display: block;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        @include m.skeleton-is-loading(c.$primary100);
    }
    &__name {
        height: 16px;
        width: 80%;
        min-width: 120px;
        border-radius: 12px;
        @include m.skeleton-is-loading(c.$primary100);
    }
    &__footer {
        height: 16px;
        width: 80%;
        min-width: 120px;
        border-radius: 12px;
        @include m.skeleton-is-loading(c.$primary100);
    }
}

@media screen and (min-width: v.$laptop) {
    .documentalFileSkelleton {
        gap: 20px;
    }
}
