@use "/src/styles/variables.scss" as v;

.editForm {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__loader {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__container {
        display: flex;
        gap: 20px;
        flex-direction: column;
        &__inputs {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
        }
        &__qr {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            &__logo {
                width: 225px;
                height: 120px;
            }
            &__label {
                display: flex;
                gap: 8px;
            }
        }
        &__companyInfo {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 12px;
        }
    }
    &__options {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 20px;
    }
}

@media screen and (min-width: v.$tablet) {
    .editForm {
        &__container {
            flex-direction: row;
            &__companyInfo {
                flex-direction: row;
            }
            &__qr {
                align-items: center;
                min-width: 180px;
                &__logo {
                    width: 120px;
                }
            }
        }

        &__options {
            flex-direction: row;
        }
    }
}
