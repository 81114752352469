@use "src/styles/colors.scss" as c;
@use "src/styles/typographies" as t;
@use "/src/styles/variables.scss" as v;

.userInfoForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        &__selectCheckbox {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        &__activationDate {
            display: flex;
            flex-direction: column;
            gap: 6px;
            &__inputs {
                display: flex;
                gap: 4px;
                min-height: 40px;
                &--disabled {
                    @extend .userInfoForm__container__activationDate__inputs;
                    height: auto;
                    align-items: center;
                }
            }
        }
    }
    &__personalInfo,
    &__adtionalInfo {
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;
    }
    &--secondTab {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-bottom: 20px;
    }
    &__companies {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;
        &__labels {
            display: flex;
            gap: 6px;
            justify-content: space-between;
        }
    }
    &__addAllDepartments {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
}

@media screen and (min-width: v.$tablet) {
    .userInfoForm {
        &__personalInfo,
        &__adtionalInfo {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
