@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.auditCommentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &__avatar {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    &__avatarName {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        overflow: hidden;
        color: c.$primary600;
        text-overflow: ellipsis;
        @include t.text("xs", "semibold");
    }
    &__actions {
        display: flex;
        align-items: center;
        gap: 24px;
    }
    &__icon {
        display: flex;
        height: 20px;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        &__svg {
            text-align: center;
            font-size: 16px;
            cursor: pointer;
            &__edit {
                color: c.$gray600;
            }
            &__delete {
                color: c.$error700;
            }
        }
    }
}
