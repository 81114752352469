@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/mixin/clamp" as m;

.departmentGridRow {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid c.$gray200;
    cursor: pointer;
    &--odd {
        @extend .departmentGridRow;
        background: c.$baseWhite;
    }
    &--even {
        @extend .departmentGridRow;
        background: c.$gray25;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        width: 100%;
        height: 100%;
    }
    &__name {
        @include t.text("xs", "medium");
        color: c.$gray800;
        @include m.textclamp();
    }
    &__icons {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
    }
}
