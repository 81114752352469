@use "/src/styles/colors.scss" as c;

@mixin disabledBadge() {
    background-color: c.$gray200 !important;
}

.badge {
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    border: 1.5px solid transparent;
    width: max-content;
    flex-shrink: 0;

    &--blue {
        @extend .badge;
        &.light {
            background-color: c.$primary50;
        }

        &.dark {
            background-color: rgba(182, 216, 238, 0.2);
        }
    }
    &--grey {
        @extend .badge;
        &.light {
            background-color: c.$gray100;
        }

        &.dark {
            background-color: rgba(245, 245, 245, 0.2);
        }
    }
    &--red {
        @extend .badge;
        &.light {
            background-color: c.$error50;
        }

        &.dark {
            background-color: rgba(255, 169, 188, 0.2);
        }
    }
    &--orange {
        @extend .badge;
        &.light {
            background-color: c.$warning50;
        }

        &.dark {
            background-color: rgba(255, 206, 157, 0.2);
        }
    }
    &--green {
        @extend .badge;
        &.light {
            background-color: c.$success50;
        }

        &.dark {
            background-color: rgba(158, 235, 135, 0.2);
        }
    }
    &--yellow {
        @extend .badge;
        &.light {
            background-color: c.$yellow100;
        }

        &.dark {
            background-color: rgb(254, 247, 195, 0.2);
        }
    }
    &--purple {
        @extend .badge;
        &.light {
            background-color: c.$purple200;
        }

        &.dark {
            background-color: rgba(230, 195, 254, 0.2);
        }
    }
    &--deepBlue {
        @extend .badge;
        &.light {
            background-color: c.$primary100;
        }

        &.dark {
            background-color: rgba(182, 216, 238, 0.2);
        }
    }
    &--deepGreen {
        @extend .badge;
        &.light {
            background-color: c.$green100;
        }

        &.dark {
            background-color: rgba(182, 216, 238, 0.2);
        }
    }
    &--teal {
        @extend .badge;
        &.light {
            background-color: c.$teal100;
        }

        &.dark {
            background-color: rgba(182, 216, 238, 0.2);
        }
    }

    &--disabled {
        @extend .badge;
        @include disabledBadge();
    }

    &--sm {
        gap: 4px;
        padding: 3px 8px;
        height: 22px;
    }
    &--md {
        gap: 4px;
        padding: 3px 8px;
        height: 24px;
    }

    &--lg {
        gap: 6px;
        padding: 6px 16px;
        height: 28px;
    }
    &--solo {
        padding: 4px;
        border-radius: 50%;
        &--sm {
            width: 22px;
        }
        &--md {
            width: 24px;
        }

        &--lg {
            width: 28px;
            padding: 0;
        }
    }
    &--clamp {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        width: 100%;
        word-break: break-word;
    }
}

.badge--outline {
    @extend .badge;
    background-color: transparent;

    &--blue {
        @extend .badge;
        border: 1.5px solid c.$primary600;
    }
    &--grey {
        @extend .badge;
        border: 1.5px solid c.$gray600;
    }
    &--red {
        @extend .badge;
        border: 1.5px solid c.$error600;
    }
    &--orange {
        @extend .badge;
        border: 1.5px solid c.$orangeDark600;
    }
    &--green {
        @extend .badge;
        border: 1.5px solid c.$success600;
    }
    &--yellow {
        @extend .badge;
        border: 1.5px solid c.$yellow600;
    }
    &--purple {
        @extend .badge;
        border: 1.5px solid c.$purple400;
    }
    &--deepBlue {
        @extend .badge;
        border: 1.5px solid c.$primary400;
    }
    &--deepGreen {
        @extend .badge;
        border: 1.5px solid c.$green400;
    }
    &--red {
        @extend .badge;
        border: 1.5px solid c.$teal600;
    }
}
