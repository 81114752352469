@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.avatarQr {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    right: -4px;
    bottom: -4px;
    border: 1px solid white;
    background-color: #397096;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    font-size: 12px;
    color: white;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0em;
    font-weight: 500;
}

@mixin avatarNoImg($color: "black") {
    background-color: $color;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: c.$baseWhite;
}

.avatar {
    width: 24px;
    min-width: 24px;
    min-height: 24px;
    @include t.text("xl", "medium");
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: relative;
    user-select: none;
    &--clickable:hover {
        box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.3);
    }
    &__notification {
        position: absolute;
        top: -7px;
        &--left {
            @extend .avatar__notification;
            left: -6px;
        }
        &--right {
            @extend .avatar__notification;
            right: -6px;
        }
    }

    &--xs {
        @extend .avatar;
        @include t.text("xs", "medium");
        width: 24px;
        min-width: 24px;
        height: 24px;

        &--zIndex {
            @extend .avatar--xs;
            z-index: 3;
            border: 1px solid c.$baseWhite;
        }
    }

    &--sm {
        @extend .avatar;
        @include t.text("md", "medium");
        width: 32px;
        min-width: 32px;
        height: 32px;

        &--zIndex {
            @extend .avatar--sm;
            z-index: 3;
            border: 1px solid c.$baseWhite;
        }
    }

    &--md {
        @extend .avatar;
        @include t.text("md", "medium");
        width: 48px;
        min-width: 48px;
        height: 48px;

        &--zIndex {
            @extend .avatar--md;
            z-index: 3;
            border: 1px solid c.$baseWhite;
        }
    }

    &--lg {
        @extend .avatar;
        @include t.text("lg", "medium");
        width: 48px;
        min-width: 48px;
        height: 48px;

        &--zIndex {
            @extend .avatar--lg;
            z-index: 3;
            border: 1px solid c.$baseWhite;
        }
    }

    &--xl {
        @extend .avatar;
        @include t.text("xl", "medium");
        width: 56px;
        min-width: 56px;
        height: 56px;

        &--zIndex {
            @extend .avatar--xl;
            z-index: 3;
            border: 1px solid c.$baseWhite;
        }
    }

    &--2xl {
        @extend .avatar;
        @include t.display("xs", "medium");
        width: 64px;
        min-width: 64px;
        height: 64px;

        &--zIndex {
            @extend .avatar--2xl;
            z-index: 3;
            border: 1px solid c.$baseWhite;
        }
    }
}

.avatarContainer {
    display: flex;
    border: none;
    align-items: center;
    gap: 8px;

    &--column {
        @extend .avatarContainer;
        flex-direction: column;
        gap: 6px;
    }
    &--row {
        @extend .avatarContainer;
        flex-direction: row;
    }
    &__role {
        color: c.$gray600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        overflow: hidden;
        word-break: break-word;
        @include t.text("sm");
    }
}
