@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.mobileAuditGroupRow {
    display: flex;
    width: 100%;
    padding: 12px;
    align-items: flex-start;
    gap: 12px;
    border-bottom: 1px solid c.$gray200;
    user-select: none;
    flex-direction: column;
    &--odd {
        @extend .mobileAuditGroupRow;
        background: c.$baseWhite;
    }
    &--even {
        @extend .mobileAuditGroupRow;
        background: c.$gray25;
    }
    &__header {
        display: flex;
        gap: 12px;
        width: 100%;
        justify-content: space-between;
        &__container {
            display: flex;
            gap: 12px;
            width: 100%;
            align-items: center;
        }
    }
    &__name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        flex: 1 0 0;
        text-overflow: ellipsis;
        overflow: hidden;
        color: c.$gray700;
        @include t.text("xs", "semibold");
    }
    &__footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 12px;
        align-items: flex-end;
    }
    &__progressbar {
        display: flex;
        gap: 8px;
        align-items: flex-end;
        width: 100%;
        max-width: 350px;
    }
    &__badgeComment {
        display: flex;
        justify-content: flex-end;
    }
}
