@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.auditPill {
    width: 100%;
    cursor: pointer;
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid c.$gray200;
    background: c.$baseWhite;
    box-shadow: c.$shadow-md;

    &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 12px;
    }

    &__valoration {
        align-self: center;
        min-width: 110px;
    }

    &__section {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;

        &--center {
            @extend .auditPill__section;
            justify-content: center;
        }

        &--row {
            @extend .auditPill__section;
            align-items: center;
            flex-direction: row;
        }

        &__progressBar {
            width: 100%;
            max-width: 300px;
        }

        &__leftRow {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        &__title {
            color: c.$gray900;
            width: auto;
            @include t.text("sm", "semibold");
        }

        &__greyDate {
            @include t.text("xs");
            color: c.$gray600;
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 4px;
        }

        &__item {
            width: auto;
        }
    }
}

@media screen and (min-width: v.$mobile) {
    .auditPill {
        flex-direction: row;
        gap: 32px;
        &__section {
            &__progressBar {
                max-width: 350px;
            }
            &__item {
                width: 100%;
            }
        }
    }
}
