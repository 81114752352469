.createAssetBtn {
    display: flex;
    align-items: center;
}

.assetGrid {
    margin: 0 20px;
    &__name {
        min-width: 100px;
    }
    &__edit {
        display: flex;
        justify-content: center;
        min-width: 60px;
    }
    &__badge {
        display: flex;
        justify-content: center;
    }
    &__small {
        width: 120px;
    }
    &__tiny {
        width: 70px;
    }
    &__actions {
        width: 80px;
    }
    &__medium {
        width: 220px;
    }
}
