@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.genericModalHeader {
    display: flex;
    width: 100%;
    background-position: center left;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    &--primary {
        background-color: c.$primary600;
    }
    &--appcc {
        background-color: c.$baseWhite;
    }

    &--radius {
        border-radius: 8px 8px 0px 0px;
    }
    &__container {
        display: flex;
        width: 100%;
        min-height: 48px;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
        &--appcc {
            border-bottom: 1px solid c.$gray300;
        }
    }
    &__titleContainer {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    &__titles {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    &__title {
        text-align: center;
        @include t.text("sm", "semibold");
        width: 100%;
        margin-left: 20px;
        &--withIcon {
            text-align: center;
            margin-left: 20px;
            @include t.text("sm", "semibold");
        }
        &--primary {
            color: c.$baseWhite;
        }
        &--appcc {
            color: c.$gray800;
        }
    }
    &__subtitle {
        text-align: center;
        @include t.text("sm", "medium");
        width: 100%;
        margin-left: 20px;
        &--withIcon {
            text-align: center;
            @include t.text("sm", "medium");
        }
        &--primary {
            color: c.$baseWhite;
        }
        &--appcc {
            color: c.$gray500;
        }
        &--primary {
            color: c.$baseWhite;
        }
        &--appcc {
            color: c.$gray800;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .genericModalHeader {
        border-radius: 8px 8px 0px 0px;
    }
}
