@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.assetGridIssueButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;

    &__title {
        color: c.$error700;
        @include t.text("sm", "semibold");
        line-height: 0px;
    }
    &__icon {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        &__svg {
            color: c.$error700;
            text-align: center;
            font-size: 16px;
        }
    }
    &:hover {
        .assetGridIssueButton__title {
            color: c.$error800;
        }
        .assetGridIssueButton__icon {
            &__svg {
                color: c.$error800;
            }
        }
    }

    &--readonly {
        cursor: default;

        &:hover {
            .assetGridIssueButton__title {
                color: c.$error700;
            }
            .assetGridIssueButton__icon {
                &__svg {
                    color: c.$error700;
                }
            }
        }
    }
}
