@use "/src/styles/colors.scss" as c;
@use "/src/styles/mixin/skelletonAnimation" as m;
@use "/src/styles/variables.scss" as v;

.documentalFolderSkelleton {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
    width: 100%;
    &__folder {
        cursor: default;
        height: 116px;
        &:hover {
            background-color: c.$baseWhite !important;
        }
    }
    &__icon {
        display: block;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        @include m.skeleton-is-loading(c.$primary100);
    }
    &__name {
        height: 16px;
        width: 80%;
        min-width: 120px;
        border-radius: 12px;
        @include m.skeleton-is-loading(c.$primary100);
    }
    &__footer {
        height: 16px;
        width: 80%;
        min-width: 120px;
        border-radius: 12px;
        @include m.skeleton-is-loading(c.$primary100);
    }
}

@media screen and (min-width: v.$mobile) {
    .documentalFolderSkelleton {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: v.$tablet) {
    .documentalFolderSkelleton {
        gap: 20px;
        grid-template-columns: repeat(3, 1fr);
    }
}
