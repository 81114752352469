@use "/src/styles/typographies.scss" as t;
@use "/src/styles/colors.scss" as c;

.editNotificationDistributionListUsersModal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__title {
        display: flex;
        @include t.text("sm", "semibold");
        color: c.$primary500;
    }
    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &__users {
            display: flex;
            flex-direction: column;
            padding-left: 20px;
            gap: 12px;
        }
    }
    &__addAllUsers {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    &__label {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
    }
}
