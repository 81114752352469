@use "/src/styles/colors.scss" as c;

.avatarColorPicker {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    justify-items: center;
    &__color {
        border-radius: 999px;
        cursor: pointer;
        appearance: none;
        background: no-repeat;
        border: none;
        padding: 0;
        &--selected {
            scale: 1.1;
            outline: 2px solid c.$gray900;
        }
        &:hover {
            scale: 1.1;
        }
    }
}
