@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.documentalFolder {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 8px;
    background-color: c.$baseWhite;
    box-shadow: c.$shadow-md;
    position: relative;
    cursor: pointer;

    &:hover {
        background-color: c.$gray100;
        .documentalFolder__name {
            text-decoration: underline;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }
    &__name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        align-self: stretch;
        overflow: hidden;
        color: c.$primary600;
        text-overflow: ellipsis;
        @include t.text("sm", "semibold");
    }
    &__info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
    &__date,
    &__totalFiles {
        color: c.$gray500;
        @include t.text("xs", "medium");
    }
    &__divider {
        color: c.$gray300;
    }
}

@media screen and (min-width: v.$mobile) {
    .documentalFolder {
        padding: 12px;
    }
}
