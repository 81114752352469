@use "/src/styles/variables.scss" as v;

.closeCompanyDates {
    display: flex;
    align-items: center;
    width: 100%;
    &__dates {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        height: 100%;
        &__switch {
            display: flex;
            align-items: flex-start;
            gap: 8px;
        }
        &__container {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            &__select {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
        &__button {
            display: flex;
            align-items: flex-start;
            padding: 9px 0;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .closeCompanyDates {
        width: 50%;
        max-width: 455px;
    }
}
