@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.avatarInitials {
    border-radius: 100%;
    position: relative;
    user-select: none;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: c.$baseWhite;

    &__user {
        @include t.text("md", "medium");
    }

    &--xs {
        @include t.text("xs", "medium");
        width: 24px;
        min-width: 24px;
        height: 24px;
        @include t.text("xs", "medium");
    }

    &--sm {
        @include t.text("md", "medium");
        width: 32px;
        min-width: 32px;
        height: 32px;
        @include t.text("md", "medium");
    }

    &--md {
        @include t.text("md", "medium");
        width: 48px;
        min-width: 48px;
        height: 48px;
        @include t.text("md", "medium");
    }

    &--lg {
        @include t.text("lg", "medium");
        width: 48px;
        min-width: 48px;
        height: 48px;
        @include t.text("lg", "medium");
    }

    &--xl {
        @include t.text("xl", "medium");
        width: 56px;
        min-width: 56px;
        height: 56px;
        @include t.text("xl", "medium");
    }

    &--2xl {
        @include t.display("xs", "medium");
        width: 64px;
        min-width: 64px;
        height: 64px;
        @include t.display("xs", "medium");
    }

    &--noImage {
        background-color: c.$gray100;
        color: c.$gray600;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &--border {
        border: 1px solid c.$baseWhite;
    }
    &--moss {
        background-color: c.$moss600;
    }
    &--greenLight {
        background-color: c.$greenLight600;
    }
    &--green {
        background-color: c.$green600;
    }
    &--teal {
        background-color: c.$teal600;
    }
    &--cyan {
        background-color: c.$cyan600;
    }
    &--blueLight {
        background-color: c.$blueLight600;
    }
    &--blue {
        background-color: c.$blue600;
    }
    &--blueDark {
        background-color: c.$blueDark600;
    }
    &--indigo {
        background-color: c.$indigo600;
    }
    &--violet {
        background-color: c.$violet600;
    }
    &--purple {
        background-color: c.$purple600;
    }
    &--fuchsia {
        background-color: c.$fuchsia600;
    }
    &--pink {
        background-color: c.$pink600;
    }
    &--rose {
        background-color: c.$rose600;
    }
    &--orange {
        background-color: c.$orange600;
    }
    &--orangeDark {
        background-color: c.$orangeDark600;
    }
    &--yellow {
        background-color: c.$yellow600;
    }
}
