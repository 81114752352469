.notificationDistributionListUserOptions {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    gap: 12px;
    &__label {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
    }
}
