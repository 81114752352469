@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.auditComment {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid c.$gray200;
    width: 100%;
    &__description {
        line-clamp: 2;
    }
    &__description {
        line-clamp: 1;
    }
    &__description,
    &__createdAt {
        color: c.$gray700;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        align-self: stretch;
        @include t.text("xs", "regular");
    }
}
