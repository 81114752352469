@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.documentalFilePage {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__header {
        display: flex;
        height: 32px;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;
    }
    &__totalFiles {
        color: c.$gray600;
        @include t.text("sm", "regular");
    }
    &__container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        height: 100%;
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 12px;
        width: 100%;
    }
}

@media screen and (min-width: v.$tablet) {
    .documentalFilePage {
        &__header {
            padding-bottom: 20px;
        }
    }
}

@media screen and (min-width: v.$laptop) {
    .documentalFilePage {
        &__container {
            gap: 20px;
        }
        &__list {
            gap: 20px;
        }
    }
}
