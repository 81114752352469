//COMMON
@forward "./common.scss";
// Routes
@forward "app/routes/components/ChangeCompany/changeCompany.scss";
@forward "app/routes/appRoutes.scss";

// Pages
@forward "app/pages/00-LOGIN/Login/LoginForm/login.scss";
@forward "app/pages/00-LOGIN/RecoverPassword/RecoverPasswordForm/recoverPassword.scss";
@forward "app/pages/00-LOGIN/ResetPassword/ResetPasswordForm/resetPassword.scss";
@forward "app/pages/00-LOGIN/SelectProfiles/selectProfiles.scss";
@forward "app/pages/01-SEG/Companies/Components/companies.scss";
@forward "app/pages/01-SEG/Companies/Components/Company/companyGrid.scss";
@forward "app/pages/01-SEG/Companies/Components/GroupCompanyModal/groupCompanyModal.scss";
@forward "app/pages/01-SEG/Companies/Components/Company/EditCompanyForm/CompanyLogo/companyLogo.scss";
@forward "app/pages/01-SEG/Companies/Components/Company/EditCompanyForm/editCompanyForm.scss";
@forward "app/pages/01-SEG/Companies/Components/Company/EditCompanyForm/CloseCompanyDates/closeCompanyDates.scss";
@forward "app/pages/01-SEG/Companies/Components/Company/MobileCompanyGridBody/mobileCompanyGridBody.scss";
@forward "app/pages/01-SEG/Companies/Components/Department/departmentGrid.scss";
@forward "app/pages/01-SEG/Companies/Components/DepartmentGridRow/departmentGridRow.scss";
@forward "app/pages/01-SEG/Companies/Components/Department/closeDepartmentModal.scss";
@forward "app/pages/01-SEG/Companies/Components/QRManagement/components/CloseQRModal/closeQRModal.scss";
@forward "app/pages/01-SEG/Companies/Components/QRManagement/components/MobileQRGridRow/mobileQRGridRow.scss";

@forward "app/pages/01-SEG/Companies/Components/newGroupCompanyForm.scss";
@forward "app/pages/01-SEG/Licenses/Components/ModulesForm/modulesForm.scss";
@forward "app/pages/01-SEG/MyProfile/myProfilePage.scss";
@forward "app/pages/01-SEG/MyProfile/TabChildrens/MyConfiguration/myConfiguration.scss";
@forward "app/pages/01-SEG/MyProfile/TabChildrens/MyInformation/myInformation.scss";
@forward "app/pages/01-SEG/Role/MobileRoleGridBody/mobileRoleGridBody.scss";
@forward "app/pages/01-SEG/Role/RoleEdit/roleEdit.scss";
@forward "app/pages/01-SEG/Role/roleGrid.scss";
@forward "app/pages/01-SEG/Role/temporaryRoles.scss";
@forward "app/pages/01-SEG/Role/EditRoleModal/editRoleModal.scss";
@forward "app/pages/01-SEG/Users/Components/MobileUserRow/mobileUserRow.scss";
@forward "app/pages/01-SEG/Users/Components/userGrid.scss";
@forward "app/pages/01-SEG/Users/newUserTabs.scss";
@forward "app/pages/01-SEG/Users/centersAndDepartmentsTab.scss";
@forward "app/pages/01-SEG/Users/userInfoForm.scss";
@forward "app/pages/01-SEG/NotificationDistributionList/NotificationDistributionListGrid/notificationDistributionList.scss";
@forward "app/pages/01-SEG/NotificationDistributionList/NotificationDistributionListGrid/components/EditNotificationDistributionListUsersModal/editNotificationDistributionListUsersModal.scss";
@forward "app/pages/01-SEG/NotificationDistributionList/NotificationDistributionListGrid/components/CreateNotificationDistributionListUsersModal/createNotificationDistributionListUsersModal.scss";
@forward "app/pages/01-SEG/NotificationDistributionList/NotificationDistributionListGrid/components/NotificationDistributionListUserOptions/notificationDistributionListUserOptions.scss";
@forward "app/pages/01-SEG/NotificationDistributionList/NotificationDistributionListGrid/components/MobileNotificationDistributionListRow/mobileNotificationDistributionListRow.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/NewDashboard/NewDashboard.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/PlaceholderDashboard/Placeholder/placeholder.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/PlaceholderDashboard/Placeholder/placeholder.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/QrChecker/QrChecker.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskCheckList/taskCheckList.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskCheckList/TaskCheckListItem/taskCheckListItem.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskCheckList/TaskCheckListTitle/taskCheckListTitle.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskComments/taskComments.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/taskDetail.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskDetailDragFile/taskDetailDragFile.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskFields/Taskfield/taskfield.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskFields/taskFields.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskFields/GroupedTaskField/groupedTaskField.scss";
@forward "app/pages/02-TAR/01-TAR-Dashboard/TaskPillList/taskPillList.scss";
@forward "app/pages/02-TAR/02-TAR-ReviewFotos/carrousel/CarrouselItem/reviewCarrouselItem.scss";
@forward "app/pages/02-TAR/02-TAR-ReviewFotos/carrousel/reviewCarrousel.scss";
@forward "app/pages/02-TAR/02-TAR-ReviewFotos/ReviewWorker/reviewWorker.scss";
@forward "app/pages/02-TAR/04-TAR-Feedback/feedback.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/BaseTaskModal/baseTaskModal.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/DataToReport/dataToReport.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/ExtraData/extraData.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskBelongs/taskbelongs.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskTemporality/taskTemporality.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskData/taskData.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskDataInputPopoverChildren/taskDataInputPopoverChildren.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/EditTask/editTask.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/TaskGrid/components/MobileTaskRow/MobileTaskBody/mobileTaskBody.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/TaskGrid/components/MobileTaskRow/mobileTaskRow.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/TaskGrid/components/MobileTaskRow/MobileTaskRowHeader/mobileTaskRowHeader.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/TaskGrid/taskgrid.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step1/StepOne.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step2/stepTwo.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step3/stepThree.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step4/stepFour.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step5/stepFive.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskTemporality/components/customWeek.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskTemporality/components/taskStart.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step4/components/stepFour.scss";
@forward "app/pages/05-QUA/Asset/AssetForm/AssetDynamicField/assetDynamicField.scss";
@forward "app/pages/05-QUA/Asset/AssetForm/AssetDynamicFields/assetDynamicFields.scss";
@forward "app/pages/05-QUA/Asset/AssetForm/assetForm.scss";
@forward "app/pages/05-QUA/Asset/AssetForm/AssetFormInfo/AssetSensorInfo/assetSensorInfo.scss";
@forward "app/pages/05-QUA/Asset/AssetGrid/assetGrid.scss";
@forward "app/pages/05-QUA/Asset/AssetGrid/components/AssetGridIssueButton/assetGridIssueButton.scss";
@forward "app/pages/05-QUA/Asset/AssetGrid/components/AssetMobileRow/assetMobileRow.scss";
@forward "app/pages/05-QUA/Asset/AssetGrid/components/AssetMobileRow/AssetMobileBadges/assetMobileBadges.scss";
@forward "app/pages/05-QUA/Asset/AssetGrid/components/AssetMobileRow/AssetMobileSensorBadges/assetMobileSensorBadges.scss";
@forward "app/pages/02-TAR/11-Alerts/alertPage.scss";
@forward "app/pages/02-TAR/11-Alerts/AlertFloatingButtons/alertFloatingButtons.scss";
@forward "app/pages/02-TAR/13-TAR-AcceptPolicy/AcceptPolicyPage.scss";
@forward "app/pages/02-TAR/14-WorkingPosition/WorkingPositionGrid/workingPositionGrid.scss";
@forward "app/pages/02-TAR/14-WorkingPosition/WorkingPositionForm/workingPositionForm.scss";
@forward "app/pages/02-TAR/14-WorkingPosition/WorkingPositionGrid/WorkingPositionGridMobileBody/workingPositionGridMobileBody.scss";
@forward "app/pages/02-TAR/15-BaseTask/BaseTaskGrid/baseTaskGrid.scss";
@forward "app/pages/02-TAR/15-BaseTask/EditBaseTask/components/BaseTaskExtraData/baseTaskExtraData.scss";
@forward "app/pages/02-TAR/15-BaseTask/EditBaseTask/components/BaseTaskDataToReport/baseTaskDataToReport.scss";
@forward "app/pages/02-TAR/15-BaseTask/EditBaseTask/editBaseTask.scss";
@forward "app/pages/02-TAR/15-BaseTask/EditBaseTask/components/BaseTaskTemporality/baseTaskTemporality.scss";
@forward "app/pages/02-TAR/15-BaseTask/EditBaseTask/components/BaseTaskTemporalityHint/baseTaskTemporalityHint.scss";
@forward "app/pages/02-TAR/15-BaseTask/MobileBaseTaskRow/mobileBaseTaskRow.scss";
@forward "app/pages/02-TAR/15-BaseTask/MobileBaseTaskRow/MobileBaseTaskRowBody/mobileBaseTaskRowBody.scss";
@forward "app/pages/02-TAR/15-BaseTask/MobileBaseTaskRow/MobileBaseTaskRowHeader/mobileBaseTaskRowHeader.scss";
@forward "app/pages/02-TAR/DESIGN-SYS/DesygnSys.scss";
@forward "app/pages/05-QUA/CorrectiveMeasure/CorrectiveMeasureGrid/correctiveMeasureGrid.scss";
@forward "app/pages/05-QUA/Issue/IssueGrid/issueGrid.scss";
@forward "app/pages/05-QUA/Issue/EditIssue/editIssue.scss";
@forward "app/pages/05-QUA/Issue/IssueGrid/components/MobileIssueRow/mobileIssueRow.scss";
@forward "app/pages/05-QUA/Issue/IssueGrid/components/MobileIssueRow/MobileIssueRowBody/mobileIssueRowBody.scss";
@forward "app/pages/05-QUA/Issue/IssueGrid/components/MobileIssueRow/MobileRowHeader/mobileRowHeader.scss";
@forward "app/pages/05-QUA/PlanAPPCC/components/PlanAPPCCCounters/planAPPCCCounters.scss";
@forward "app/pages/05-QUA/PlanAPPCC/MobilePlanRow/mobilePlanRow.scss";
@forward "app/pages/05-QUA/PlanAPPCC/MobilePlanRow/MobilePlanRowBody/mobilePlanRowBody.scss";
@forward "app/pages/05-QUA/PlanAPPCC/MobilePlanRow/MobilePlanRowFooter/mobilePlanRowFooter.scss";
@forward "app/pages/05-QUA/PlanAPPCC/PlanAPPCCGrid/planAPPCCGrid.scss";
@forward "app/pages/Notification/Notification/Notification.scss";
@forward "app/pages/Notification/NotificationPage.scss";
@forward "app/pages/05-QUA/CorrectiveMeasure/CorrectiveMeasureGrid/components/CorrectiveMeasureModal/correctiveMeasureModal.scss";
@forward "app/pages/05-QUA/CorrectiveMeasure/CorrectiveMeasureGrid/components/MobileCorrectiveMeasureRow/mobileCorrectiveMeasureRow.scss";
@forward "app/pages/02-TAR/14-WorkingPosition/PrintQr/printQr.scss";
@forward "app/pages/FAQ/FAQList/FAQList.scss";
@forward "app/pages/01-SEG/PatchNotes/PatchNotesGrid/patchNotesGrid.scss";
@forward "app/pages/01-SEG/PatchNotes/PatchNotesGrid/components/MobilePatchNoteRow/mobilePatchNoteRow.scss";
@forward "app/pages/01-SEG/PatchNotes/PatchNotesGrid/components/PatchNotesModal/patchNotesModal.scss";
@forward "app/pages/07-DOC/DocumentalFolderPage/documentalFolderPage.scss";
@forward "app/pages/07-DOC/DocumentalFolderPage/DocumentalFolderPageHeader/documentalFolderPageHeader.scss";
@forward "app/pages/07-DOC/DocumentalFilePage/documentalFilePage.scss";
@forward "app/pages/07-DOC/DocumentalFilePage/DocumentalFilePageHeader/documentalFilePageHeader.scss";

// Analist
@forward "app/pages/03-REP/01-AnalystRankingPage/AnalistDetail/AnalistDetail.scss";
@forward "app/pages/03-REP/01-AnalystRankingPage/AnalistDetail/AnalistDetailBody/AnalistDetailBody.scss";
@forward "app/pages/03-REP/01-AnalystRankingPage/AnalistDetail/AnalistDetailTaskList/AnalistDetailTaskList.scss";
@forward "app/pages/03-REP/01-AnalystRankingPage/SupervisordetailPage/SupervisordetailPage.scss";

// Components V2
@forward "app/components_v2/__filters/BaseTaskFilter/baseTaskFilter.scss";
@forward "app/components_v2/AppHeader/appHeader.scss";
@forward "app/components_v2/AttachedFile/attachedFile.scss";
@forward "app/components_v2/Attachment/attachment.scss";
@forward "app/components_v2/AudioRecorder/audioRecorder.scss";
@forward "app/components_v2/AvatarStarRating/avatarStarRating.scss";
@forward "app/components_v2/Badge/badge.scss";
@forward "app/components_v2/Badge/BadgeTitle/badgeTitle.scss";
@forward "app/components_v2/Badge/BagdgeIcon/BadgeIcon.scss";
@forward "app/components_v2/BarChart/barChart.scss";
@forward "app/components_v2/BarChart/BarChartCustom/barChartCustom.scss";
@forward "app/components_v2/BreadCrumbs/breadcrumbs.scss";
@forward "app/components_v2/BreadCrumbs/Crumb/crumb.scss";
@forward "app/components_v2/CheckBox/checkbox.scss";
@forward "app/components_v2/CloseRangeDates/closeRangeDates.scss";
@forward "app/components_v2/CompanyDepartmentBox/CompanyDepartmentBox.scss";
@forward "app/components_v2/CriticalTask/criticalTask.scss";
@forward "app/components_v2/CustomQrScanner/customQrScanner.scss";
@forward "app/components_v2/DashboardHeader/dashboardHeader.scss";
@forward "app/components_v2/Divider/divider.scss";
@forward "app/components_v2/DividerWithText/DividerWithText.scss";
@forward "app/components_v2/DotsLoading/dotsLoading.scss";
@forward "app/components_v2/DoughnutChart/DoughnutChart.scss";
@forward "app/components_v2/DragFileWithLabel/dragFileWithLabel.scss";
@forward "app/components_v2/ErrorIcon/errorIcon.scss";
@forward "app/components_v2/ErrorMessage/errorMessage.scss";
@forward "app/components_v2/Errors/wentWrong.scss";
@forward "app/components_v2/FullHeightOpacity/fullHeightOpacity.scss";
@forward "app/components_v2/GenerateQr/generateQr.scss";
@forward "app/components_v2/GoBackIcon/goBackIcon.scss";
@forward "app/components_v2/Header/header.scss";
@forward "app/components_v2/Header/HeaderItem/headerItem.scss";
@forward "app/components_v2/Header/HeaderRightItem/headerRightItem.scss";
@forward "app/components_v2/HeaderAnalyst/headerAnalyst.scss";
@forward "app/components_v2/Icon/icon.scss";
@forward "app/components_v2/ImagePreview/imagePreview.scss";
@forward "app/components_v2/Layout/FormLayout/formLayout.scss";
@forward "app/components_v2/LineChart/LineChart.scss";
@forward "app/components_v2/LineChart/LineChartCustom/lineChartCustom.scss";
@forward "app/components_v2/ListEmptyState/listEmptyState.scss";
@forward "app/components_v2/MarkableIcon/markableIcon.scss";
@forward "app/components_v2/MenuSideBar/CollapsableSidebar/collapsableSidebar.scss";
@forward "app/components_v2/MenuSideBar/MenuSideBar.scss";
@forward "app/components_v2/MenuSideBar/SidebarItem/sidebarItem.scss";
@forward "app/components_v2/MobileFooter/mobileFooter.scss";
@forward "app/components_v2/MobileHeader/mobileHeader.scss";
@forward "app/components_v2/MobileHeader/MobileHeaderTitle/mobileHeaderTitle.scss";
@forward "app/components_v2/MultiPercentageBar/MultiPercentageBar.scss";
@forward "app/components_v2/MultiProgressBar/multiProgressBar.scss";
@forward "app/components_v2/NotificationIcon/notificationIcon.scss";
@forward "app/components_v2/NotificationPill/notificationPill.scss";
@forward "app/components_v2/NumberIcon/numberIcon.scss";
@forward "app/components_v2/OptionCompany/optionCompany.scss";
@forward "app/components_v2/PercentageStars/PercentageStars.scss";
@forward "app/components_v2/PlanAPPCCGridHeader/planAPPCCGridHeader.scss";
@forward "app/components_v2/Popover/popover.scss";
@forward "app/components_v2/Popover/popover.scss";
@forward "app/components_v2/ProgressBarWithLabel/ProgressBarWithLabel.scss";
@forward "app/components_v2/RadioButton/radioButton.scss";
@forward "app/components_v2/RadioButton/RadioGroup/radioGroup.scss";
@forward "app/components_v2/RoundedFilterButton/roundedFilterButton.scss";
@forward "app/components_v2/RoundedFilterButtonGroup/roundedFilterButtonGroup.scss";
@forward "app/components_v2/Slider/SlideItem/slideItem.scss";
@forward "app/components_v2/Spinner/spinner.scss";
@forward "app/components_v2/Starsvaloration/starsValoration.scss";
@forward "app/components_v2/SupervisorAnalist/CircularProgressBar/CircularProgressBar.scss";
@forward "app/components_v2/SupervisorTeamCount/SupervisorTeamCount.scss";
@forward "app/components_v2/SwitchDragFile/switchDragFile.scss";
@forward "app/components_v2/SystemMessage/systemMessage.scss";
@forward "app/components_v2/Tab/tab.scss";
@forward "app/components_v2/Tabs/tabs.scss";
@forward "app/components_v2/Tag/tag.scss";
@forward "app/components_v2/TaskReview/TaskReview.scss";
@forward "app/components_v2/Toasts/toast.scss";
@forward "app/components_v2/UploadPhoto/uploadPhoto.scss";
@forward "app/components_v2/UsersLoginItem/usersLoginItem.scss";
@forward "app/components_v2/UserValoration/userValoration.scss";
@forward "app/components_v2/ValorationDetail/DoughnutValorationDetail/doughnutValorationDetail.scss";
@forward "app/components_v2/ValorationDetail/FastCommentValorationDetail/fastCommentValorationDetail.scss";
@forward "app/components_v2/ValorationDetail/FastReviewValorationDetail/fastReviewValorationDetail.scss";
@forward "app/components_v2/ValorationDetail/supervisorValorationDetail.scss";
@forward "app/components_v2/ValorationDetail/TaskProgressBarValorationDetail/taskProgressBarValorationDetail.scss";
@forward "app/components_v2/ValorationDetail/TeamValorationDetail/teamValorationDetail.scss";
@forward "app/components_v2/ValorationDetail/workerValorationDetail.scss";
@forward "app/components_v2/WebcamCapture/MediaCapture/mediaCapture.scss";
@forward "app/components_v2/WebcamCapture/webcamCapture.scss";
@forward "app/components_v2/WhiteBox/whiteBox.scss";
@forward "app/components_v2/WizardTask/TaskTemporalityRangeHour/taskTemporalityRangeHour.scss";
@forward "app/components_v2/WorkerPill/WorkerPill.scss";
@forward "app/components_v2/HueSlider/hueSlider.scss";

// SecondaryFilter
@forward "app/components_v2/SecondaryFilter/secondaryFilter.scss";
@forward "app/components_v2/SecondaryFilter/SecondaryFilterClearFilters/secondaryFilterClearFilters.scss";
@forward "app/components_v2/SecondaryFilter/SecondaryFilterFooter/secondaryFilterFooter.scss";

// SecondaryFilter Dashboard
@forward "app/components_v2/__filters/DashboardFilter/dashboardFilter.scss";
@forward "app/components_v2/__filters/DashboardFilter/components/FilterStars/filterStars.scss";
@forward "app/components_v2/__filters/TaskHistoryFilter/taskHistoryFilter.scss";

// TaskHistory
@forward "app/pages/02-TAR/09-TAR-Historic/TaskHistoryGrid/taskHistoryGrid.scss";
@forward "app/pages/02-TAR/09-TAR-Historic/TaskHistoryGrid/components/MobileTaskInstanceRow/mobileTaskInstanceRow.scss";
@forward "app/pages/02-TAR/09-TAR-Historic/TaskHistoryGrid/components/TaskHistoryHeader/taskHistoryHeader.scss";

// SecondaryFilter ReviewTask
@forward "app/components_v2/__filters/ReviewTaskFilter/reviewTaskFilter.scss";

// Pagination
@forward "app/components_v2/Pagination/pagination.scss";
@forward "app/components_v2/Pagination/PaginationIcon/paginationIcon.scss";

// Table
@forward "app/components_v2/Table/table.scss";
@forward "app/components_v2/Table/TableHeader/tableHeader.scss";
@forward "app/components_v2/Table/TableBody/tableBody.scss";
@forward "app/components_v2/Table/CellTitle/cellTitle.scss";
@forward "app/components_v2/Table/CellPhoto/cellPhoto.scss";
@forward "app/components_v2/Table/TableTabHeader/tableTabHeader.scss";
@forward "app/components_v2/Table/TableTabHeaderActions/tableTabHeaderActions.scss";
@forward "app/components_v2/Table/TableTabOneRowHeader/tableTabOneRowHeader.scss";
@forward "app/components_v2/Table/TableCollapsable/tableCollapsable.scss";
@forward "app/components_v2/Table/CellIcons/cellIcons.scss";
@forward "app/components_v2/Table/CellWithAvatar/cellWithAvatar.scss";
@forward "app/components_v2/Table/TableError/tableError.scss";
@forward "app/components_v2/Table/Mobile/MobileTableBody/mobileTableBody.scss";
@forward "app/components_v2/Table/Mobile/MobileTableHeader/mobileTableHeader.scss";
@forward "app/components_v2/Table/TableSelectCompany/tableSelectCompany.scss";
@forward "app/components_v2/Table/TableNoTabHeader/tableNoTabHeader.scss";

//CHAT
@forward "app/components_v2/Chat/Message/message.scss";
@forward "app/components_v2/Chat/ChatInputBox/chatInputBox.scss";

// Inputs
@forward "app/components_v2/__inputs/SelectCustomChildren/SelectCustomChildren.scss";
@forward "app/components_v2/__inputs/EmojisInput/emojisInput.scss";
@forward "app/components_v2/__inputs/UploadFile/uploadFile.scss";
@forward "app/components_v2/__inputs/Input/input.scss";
@forward "app/components_v2/__inputs/Hint/hint.scss";
@forward "app/components_v2/__inputs/DateRangePicker/DateRangePicker.scss";
@forward "app/components_v2/__inputs/DateTimePicker/dateTimePicker.scss";
@forward "app/components_v2/__inputs/LanguageSelector/languageSelector.scss";
@forward "app/components_v2/__inputs/SelectOptions/selectOptions.scss";
@forward "app/components_v2/__inputs/OptionWithAvatar/optionWithAvatar.scss";
@forward "app/components_v2/__inputs/OptionWithCounter/optionWithCounter.scss";
@forward "app/components_v2/__inputs/SelectOptionsSingleValueWithAvatar/selectOptionsSingleValueWithAvatar.scss";
@forward "app/components_v2/__inputs/TextArea/TextArea.scss";
@forward "app/components_v2/__inputs/DayBubble/dayBubble.scss";
@forward "app/components_v2/__inputs/SelectWeekDayBubbles/selectWeekDayBubbles.scss";
@forward "app/components_v2/__inputs/TimePicker/timePicker.scss";
@forward "app/components_v2/__inputs/TimeRangePicker/timeRangePicker.scss";
@forward "app/components_v2/__inputs/Switch/switch.scss";
@forward "app/components_v2/__inputs/IconSelect/IconSelect.scss";
@forward "app/components_v2/__inputs/Label/label.scss";
@forward "app/components_v2/__inputs/OtpInput/otpInput.scss";
@forward "app/components_v2/__inputs/SquareInput/squareInput.scss";
@forward "app/components_v2/__inputs/ReadOnlyInput/readOnlyInput.scss";
@forward "app/components_v2/__inputs/InputWithPopover/inputWithPopover.scss";

//Buttons
@forward "app/components_v2/__buttons/FilterButton/filterButton.scss";
@forward "app/components_v2/__buttons/Buttongroup/ButtonGroup.scss";
@forward "app/components_v2/__buttons/AddNewButton/addNewButton.scss";
@forward "app/components_v2/__buttons/SquareButton/squareButton.scss";
@forward "app/components_v2/__buttons/FloatingButton/floatingButton.scss";
@forward "app/components_v2/__buttons/Button/button.scss";
@forward "app/components_v2/__buttons/Button/ButtonIcon/butonIcon.scss";

// Modals
@forward "app/components_v2/__modals/base/GenericModal/genericModal.scss";
@forward "app/components_v2/__modals/base/GenericModal/GenericModalFooter/genericModalFooter.scss";
@forward "app/components_v2/__modals/base/GenericModal/GenericModalHeader/genericModalHeader.scss";
@forward "app/components_v2/__modals/base/ImageModal/image.scss";
@forward "app/components_v2/__modals/base/MailModal/mailModal.scss";
@forward "app/components_v2/__modals/base/Modal/modal.scss";
@forward "app/components_v2/__modals/base/SideBar/sideBar.scss";
@forward "app/components_v2/__modals/base/SliderModal/sliderModal.scss";

@forward "app/components_v2/__modals/ScheduleNotificationModal/scheduleNotificationModal.scss";
@forward "app/components_v2/__modals/NotificationsModal/NotificationsModal.scss";
@forward "app/components_v2/__modals/TaskValorationModal/TaskValorationModal.scss";
@forward "app/components_v2/__modals/TaskValorationModal/TaskValorationModalHeader/taskValorationModalHeader.scss";
@forward "app/components_v2/__modals/ExportCsvModal/exportCsvModal.scss";
@forward "app/components_v2/__modals/ReasignModal/reasignModal.scss";
@forward "app/components_v2/__modals/ReasignModal/ReasignTo/reasignTo.scss";
@forward "app/components_v2/__modals/ReasignModal/ReasignSelect/reasignSelect.scss";
@forward "app/components_v2/__modals/ReasignModal/ReasignTypeSelector/reasignTypeSelector.scss";
@forward "app/components_v2/__modals/ReasignModal/ReasignDates/ReasignDates.scss";
@forward "app/components_v2/__modals/AddUsersModal/AddUsersModal.scss";
@forward "app/components_v2/__modals/AddUsersModal/AddUsersModal.scss";
@forward "app/components_v2/__modals/AlertNotificationsModal/alertNotificationsModal.scss";
@forward "app/components_v2/__modals/AlertNotificationsModal/AlertNotificationsModalHeader/alertNotificationsModalHeader.scss";
@forward "app/components_v2/__modals/CloseCompanyModal/closeCompanyModal.scss";
@forward "app/components_v2/__modals/CloseCompanyModal/components/openClosedOptionCompanyCollapsable.scss";
@forward "app/components_v2/__modals/ConfirmModal/confirmModal.scss";
@forward "app/components_v2/__modals/ExportCsvModal/exportCsvModal.scss";
@forward "app/components_v2/__modals/NotificationsModal/NotificationsModal.scss";
@forward "app/components_v2/__modals/ReasignModal/ReasignDates/ReasignDates.scss";
@forward "app/components_v2/__modals/ReasignModal/reasignModal.scss";
@forward "app/components_v2/__modals/ReasignModal/ReasignSelect/reasignSelect.scss";
@forward "app/components_v2/__modals/ReasignModal/ReasignTo/reasignTo.scss";
@forward "app/components_v2/__modals/ReasignModal/ReasignTypeSelector/reasignTypeSelector.scss";
@forward "app/components_v2/__modals/TaskValorationModal/TaskValorationModal.scss";
@forward "app/components_v2/__modals/ValorationDetailModal/ValorationDetailModal.scss";
@forward "app/components_v2/__modals/FullScreenModal/fullScreenModal.scss";
@forward "app/components_v2/__modals/FullScreenModal/FullScreenModalHeader/fullScreenModalHeader.scss";
@forward "app/components_v2/__modals/SendPatchNotesModal/sendPatchNotesModal.scss";

@forward "app/components_v2/__modals/FullScreenQrModal/fullScreenQrModal.scss";
@forward "app/components_v2/__modals/FullScreenQrModal/FullScreenQrModalHeader/fullScreenQrModalHeader.scss";
@forward "app/components_v2/__modals/FullScreenQrModal/FullScreenQrModalManual/fullScreenQrModalManual.scss";
@forward "app/components_v2/__modals/FullScreenQrModal/FullScreenQrModalManual/FullScreenQrModalManualPlaceholder/fullScreenQrModalManualPlaceholder.scss";

@forward "app/components_v2/__modals/SensorModal/SensorNotLinkedModal/sensorNotLinkedModal.scss";
@forward "app/components_v2/__modals/SensorModal/SensorNotLinkedModal/SensorNotLinkedModalFooter/sensorNotLinkedModalFooter.scss";
@forward "app/components_v2/__modals/SensorModal/SensorLinkedModal/sensorLinkedModal.scss";
@forward "app/components_v2/__modals/SensorModal/SensorLinkedModal/SensorLinkedModalBody/sensorLinkedModalBody.scss";
@forward "app/components_v2/__modals/SensorModal/SensorLinkedModal/SensorLinkedModalFooter/sensorLinkedModalFooter.scss";
@forward "app/components_v2/__modals/SensorModal/SensorModalTitle/sensorModalTitle.scss";
@forward "app/components_v2/__modals/SensorModal/SensorModalText/sensorModalText.scss";

@forward "app/components_v2/__modals/NotificationsModal/NotificationsDepartmentModal/notificationsDepartmentModal.scss";
@forward "app/components_v2/__modals/NotificationsModal/NotificationsQrModal/notificationsQrModal.scss";
@forward "app/components_v2/__modals/NotificationsModal/NotificationsCompanyModal/notificationsCompanyModal.scss";

@forward "app/components_v2/__modals/PendingTasksCompanyDepartmentModal/pendingTasksCompanyDepartmentModal.scss";
@forward "app/components_v2/__modals/PendingTasksCompanyDepartmentModal/EntityPendingTasksCard/entityPendingTasksCard.scss";

@forward "app/components_v2/__modals/DocumentalFileModal/documentalFileModal.scss";
@forward "app/components_v2/__modals/DocumentalFileModal/DocumentalFileModalHeader/documentalFileModalHeader.scss";
@forward "app/components_v2/__modals/DocumentalFileModal/DocumentalFileModalBody/documentalFileModalBody.scss";
@forward "app/components_v2/__modals/DocumentalFileModal/DocumentalFileModalTitle/documentalFileModalTitle.scss";
@forward "app/components_v2/__modals/DocumentalFileModal/DocumentalFileModalText/documentalFileModalText.scss";

@forward "app/components_v2/__modals/DocumentalFolderModal/documentalFolderModal.scss";

// Skelletons
@forward "app/components_v2/__skelletons/TaskPillSkelleton/TaskPillSkelleton.scss";
@forward "app/components_v2/__skelletons/AuditPillSkelleton/AuditPillSkelleton.scss";
@forward "app/components_v2/__skelletons/AvatarSkelleton/avatarSkelleton.scss";
@forward "app/components_v2/__skelletons/StarAndValorationSkeleton/starAndValorationSkeleton.scss";
@forward "app/components_v2/__skelletons/SystemNotificationSkelleton/systemNotificationSkelleton.scss";
@forward "app/components_v2/__skelletons/DateRangeSkelleton/dateRangeSkelleton.scss";
@forward "app/components_v2/__skelletons/CarrouselItemSkelleton/carrouselItemSkelleton.scss";
@forward "app/components_v2/__skelletons/AvatarRatingSkelleton/avatarRatingSkelleton.scss";
@forward "app/components_v2/__skelletons/TaskPillWithStarsSkelleton/Mobile/taskPillWithStarsSkelletonMobile.scss";
@forward "app/components_v2/__skelletons/TaskPillWithStarsSkelleton/Mobile/TaskPillBodyHeaderSkelleton/taskPillBodyHeaderSkelleton.scss";
@forward "app/components_v2/__skelletons/TaskPillWithStarsSkelleton/Mobile/TaskPillBodySkelleton/taskPillBodySkelleton.scss";
@forward "app/components_v2/__skelletons/TaskPillWithStarsSkelleton/Desktop/TaskPillWithStarsSkelletonDesktop/taskPillWithStarsSkelletonDesktop.scss";
@forward "app/components_v2/__skelletons/TaskPillWithStarsSkelleton/TaskPillBodyRatingSkelleton/taskPillBodyRatingSkelleton.scss";
@forward "app/components_v2/__skelletons/MobileHeaderTitleSkelleton/mobileHeaderTitleSkelleton.scss";
@forward "app/components_v2/__skelletons/HeaderAnalystSkelleton/headerAnalystSkelleton.scss";
@forward "app/components_v2/__skelletons/RankingPillSkelleton/RankingPillSkelleton.scss";
@forward "app/components_v2/__skelletons/RankingPillListSkeleton/RankingPillListSkeleton.scss";
@forward "app/components_v2/__skelletons/OptionCompanySkelleton/optionCompanySkelleton.scss";
@forward "app/components_v2/__skelletons/BreadcrumbsSkeleton/breadcrumbsSkeleton.scss";
@forward "app/components_v2/__skelletons/UsersLoginItemSkelleton/usersLoginItemSkelleton.scss";
@forward "app/components_v2/__skelletons/CrudHeaderLeftNodeSkelleton/crudHeaderLeftNodeSkelleton.scss";
@forward "app/components_v2/__skelletons/LegendItemSkelleton/legendItemSkelleton.scss";
@forward "app/components_v2/__skelletons/TabSkelleton/tabSkelleton.scss";
@forward "app/components_v2/__skelletons//DocumentalFolderSkelleton/documentalFolderSkelleton.scss";
@forward "app/components_v2/__skelletons/DocumentalFileSkelleton/documentalFileSkelleton.scss";

// Stars
@forward "app/components_v2/__stars/StarsReviews/StarsReviews.scss";
@forward "app/components_v2/__stars/StarValoration/starValoration.scss";
@forward "app/components_v2/__stars/StarPercentage/starPercentage.scss";
@forward "app/components_v2/__stars/StarRatingBadge/starRatingBadge.scss";

// Draggable Files
@forward "app/components_v2/__draggableFiles/DragFileWithPreview/dragFileWithPreview.scss";
@forward "app/components_v2/__draggableFiles/DragFileWithName/dragFileWithName.scss";
@forward "app/components_v2/__draggableFiles/DragFile/DragFile.scss";

// Containers
@forward "app/components_v2/__containers/AuthLayout/authLayout.scss";
@forward "app/components_v2/__containers/TabHeader/tabHeader.scss";
@forward "app/components_v2/__containers/LayoutContainer/layoutContainer.scss";
@forward "app/components_v2/__containers/Container/container.scss";
@forward "app/components_v2/__containers/AlertPillList/alertPillList.scss";
@forward "app/components_v2/__containers/RadioCheckboxContainer/radioCheckboxContainer.scss";
@forward "app/components_v2/__containers/PageContainer/pageContainer.scss";
@forward "app/components_v2/Layout/CrudHeader/crudHeader.scss";
@forward "app/components_v2/Layout/AnalistLayout/analistLayout.scss";

// Dropdowns
@forward "app/components_v2/Dropdown/DropdownList/dropdownList.scss";
@forward "app/components_v2/Dropdown/DropdownListItem/dropdownListItem.scss";

// Wizard header
@forward "app/components_v2/WizardHeader/wizardHeader.scss";
@forward "app/components_v2/WizardHeader/WizardHeaderItem/wizardHeaderItem.scss";
@forward "app/components_v2/WizardHeader/WizardHeaderNumber/WizardHeaderNumber.scss";

// Role collapse
@forward "app/components_v2/RoleCollapseChecboxes/roleCollapseChecboxes.scss";
@forward "app/components_v2/RoleCollapseChecboxes/RoleCollapseHeader/roleCollapseHeader.scss";
@forward "app/components_v2/RoleCollapseChecboxes/RoleCollapseBody/roleCollapseBody.scss";
@forward "app/components_v2/RoleCollapseChecboxes/RoleCollapseBody/RoleCollapseBodyTitles/roleCollapseBodyTitles.scss";
@forward "app/components_v2/RoleCollapseChecboxes/RoleCollapseRow/roleCollapseRow.scss";
@forward "app/components_v2/RoleCollapseChecboxes/RoleCollapseRow/CheckBoxRole/checkBoxRole.scss";

// Design System
@forward "app/pages/02-TAR/DESIGN-SYS/TableSection/tableSection.scss";

// New Wizards
@forward "app/components_v2/wizards/roles&permissions/rolesWizard.scss";
@forward "app/components_v2/wizards/Company/companyWizard.scss";
@forward "app/components_v2/wizards/wizard.scss";
@forward "app/components_v2/wizards/WizardLicense/StepTwo/stepTwo.scss";
@forward "app/components_v2/wizards/WizardLicense/StepFour/stepFour.scss";
@forward "app/components_v2/wizards/WizardLicense/StepFour/components/StepFourInfo/stepFourInfoFix.scss";
@forward "app/components_v2/PostLogin/wizard/SelectDeparmentWizard.scss";
@forward "app/components_v2/Wizard/WizardForm/wizardForm.scss";

// Ranking
@forward "app/components_v2/RankingPill/rankingPill.scss";
@forward "app/components_v2/RankingPill/RankingPillCounter/rankingPillCounter.scss";
@forward "app/components_v2/Layout/RankingLayout/RankingHeader/rankingHeader.scss";
@forward "app/components_v2/RankingPill/RankingPillInfo/rankingPillInfo.scss";
@forward "app/components_v2/RankingPill/RankingPillCounters/rankingPillCounters.scss";
@forward "app/components_v2/RankingPillList/rankingPillList.scss";
@forward "app/components_v2/__filters/RankingFilter/rankingFilter.scss";

// Collapsable
@forward "app/components_v2/Collapsable/collapsable.scss";
@forward "app/components_v2/Collapsable/CollapsableTitle/collapsableTitle.scss";
@forward "app/components_v2/Collapsable/CollapsableBody/collapsableBody.scss";

// RegexValidator
@forward "app/components_v2/RegexValidator/regexValidator.scss";
@forward "app/components_v2/RegexValidator/RegexValidatorItem/regexValidatorItem.scss";

// Taskpill
@forward "app/components_v2/TaskPill/TaskPill.scss";
@forward "app/components_v2/TaskPillPhoto/taskPillPhoto.scss";
@forward "app/components_v2/TaskPill/TaskPillBodyDesktop/taskPillBodyDesktop.scss";
@forward "app/components_v2/TaskPill/TaskPillPercentage/taskPillPercentage.scss";

// Issue
@forward "app/components_v2/__modals/IssueAssetsModal/IssueAssetStepTitle/issueAssetStepTitle.scss";
@forward "app/components_v2/__modals/IssueAssetsModal/IssueAssetStepSubtitle/issueAssetStepSubtitle.scss";
@forward "app/components_v2/__modals/IssueAssetsModal/IssueAssetModalStep/issueAssetModalStep.scss";
@forward "app/components_v2/__modals/IssueModal/issueModal.scss";
@forward "app/components_v2/__modals/IssueModal/IssueModalHeader/issueModalHeader.scss";

// ProgressBar
@forward "app/components_v2/ProgressBar/progressBar.scss";

// InstanceSelector
@forward "app/components_v2/AppHeader/InstanceSelector.scss";

// SelectWithActions
@forward "app/components_v2/SelectWithActions/selectWithActions.scss";
@forward "app/components_v2/SelectWithActions/SelectWithActionsMenu/selectWithActionsMenu.scss";
@forward "app/components_v2/SelectWithActions/SelectWithActionsItem/selectWithActionsItem.scss";
@forward "app/components_v2/SelectWithActions/SelectWithActionNoOptions/selectWithActionNoOptions.scss";
@forward "app/components_v2/SelectWithActions/SelectWithActionsLastItems/selectWithActionsLastItems.scss";

// Audit
@forward "app/pages/05-QUA/AuditTemplate/AuditTemplateGrid/AuditBox/auditBox.scss";
@forward "app/pages/05-QUA/AuditTemplate/AuditTemplateGrid/AuditBox/AuditBoxModal/auditBoxModal.scss";
@forward "app/pages/05-QUA/AuditTemplate/AuditTemplateGrid/AuditBox/AuditBoxBody/auditBoxBody.scss";
@forward "app/pages/05-QUA/AuditTemplate/AuditTemplateGrid/AuditBox/AuditBoxHeader/auditBoxHeader.scss";
@forward "app/pages/05-QUA/AuditTemplate/AuditTemplateGrid/AuditGroupGrid/AuditGroupCheckListModal/auditGroupCheckListModal.scss";
@forward "app/pages/05-QUA/AuditTemplate/AuditTemplateGrid/AuditGroupGrid/auditGroupGrid.scss";
@forward "app/pages/05-QUA/AuditTemplate/AuditTemplateGrid/auditTemplateGrid.scss";
@forward "app/pages/05-QUA/Audit/AuditGrid/components/StartAuditModal/startAuditModal.scss";
@forward "app/pages/05-QUA/Audit/AuditGrid/components/AuditPill/AuditPill.scss";
@forward "../app/pages/05-QUA/Audit/AuditGrid/components/AuditPill/AuditPillStars/auditPillStars.scss";
@forward "app/pages/05-QUA/Audit/AuditGrid/components/AuditSelector/auditSelector.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/editAudit.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/EditAuditHeader/editAuditHeader.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/tabs/AuditStart/components/AuditChecklistModal/auditChecklistModal.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/tabs/AuditStart/components/AuditGroupCheckListModalActions/auditGroupCheckListModalActions.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/tabs/AuditStart/components/AuditChecklistItem/auditChecklistItem.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/tabs/AuditStart/components/AuditGroupCheckList/auditGroupCheckList.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/tabs/AuditStart/components/AuditGroupMobile/auditGroupMobile.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/tabs/AuditIssues/components/AuditIssuePill/auditIssuePill.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/tabs/AuditIssues/components/AuditIssuePillList/auditIssuePillList.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/tabs/AuditIssues/components/AuditIssuePillListSkelleton/auditIssuePillListSkelleton.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/tabs/AuditReport/auditReport.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/tabs/AuditReport/components/AuditReportGrid/auditReportGrid.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/tabs/AuditReport/components/AuditReportBarChart/auditReportBarChart.scss";
@forward "app/pages/05-QUA/Audit/EditAudit/tabs/AuditReport/components/MobileAuditGroupCheckListInstance/mobileAuditGroupCheckListInstance.scss";
@forward "app/pages/05-QUA/Audit/PrintAuditReport/printAuditReport.scss";
@forward "app/pages/05-QUA/Audit/AuditGrid/components/AuditPill/AuditPill.scss";
@forward "app/pages/05-QUA/Audit/AuditGrid/auditGrid.scss";
@forward "app/pages/05-QUA/Audit/AuditGrid/components/MobileAuditRow/mobileAuditRow.scss";
@forward "app/pages/05-QUA/AuditTemplate/AuditTemplateGrid/AuditGroupGrid/AuditGroupCheckListModal/AuditGroupCheckListItem/auditGroupCheckListItem.scss";

// Base Audit
@forward "app/pages/05-QUA/BaseAuditGroupCheckListItem/BaseAuditGroupCheckListItemModal/baseAuditGroupCheckListItemModal.scss";
@forward "app/pages/05-QUA/BaseAuditGroupCheckListItem/BaseAuditGroupCheckListItemGrid/baseAuditGroupCheckListItemGrid.scss";
@forward "app/pages/05-QUA/BaseAuditGroupCheckListItem/MobileBaseAuditGroupCheckListItemRow/mobileBaseAuditGroupCheckListItemRow.scss";

// Audit Components
@forward "app/components_v2/AuditComment/auditComment.scss";
@forward "app/components_v2/AuditComment/AuditCommentHeader/auditCommentHeader.scss";

// Legend
@forward "app/components_v2/Legend/legend.scss";
@forward "app/components_v2/Legend/LegendItem/legendItem.scss";
@forward "app/components_v2/Legend/LegendItemMark/legendItemMark.scss";

// Popovers
@forward "app/components_v2/__popovers/AvatarPopover/avatarPopover.scss";

// DataToReport READONLY
@forward "app/pages/02-TAR/07-TAR-CRUD/components/DynamicFieldItemReadOnly/dynamicFieldItemReadOnly.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/components/DataToReportReadOnly/dataToReportReadOnly.scss";
@forward "app/pages/02-TAR/07-TAR-CRUD/components/ChecklistItemReadOnly/checklistItemReadOnly.scss";

// Issue Component
@forward "app/components_v2/Issue/issue.scss";
@forward "app/components_v2/Issue/IssueBody/issueBody.scss";
@forward "app/components_v2/Issue/IssueBody/IssueBodyTitle/issueBodyTitle.scss";
@forward "app/components_v2/Issue/IssueBody/IssueBodyDescription/issueBodyDescription.scss";
@forward "app/components_v2/Issue/IssueBody/IssueBodyAsset/issueBodyAsset.scss";
@forward "app/components_v2/Issue/IssueBody/IssueBodyAsset/IssueBodyAssetFieldList/issueBodyAssetFieldList.scss";
@forward "app/components_v2/Issue/IssueBody/IssueBodyAsset/IssueBodyAssetField/issueBodyAssetField.scss";
@forward "app/components_v2/Issue/IssueBody/IssueBodyResponsible/issueBodyResponsible.scss";
@forward "app/components_v2/Issue/IssueBody/IssueBodyAttachments/issueBodyAttachments.scss";
@forward "app/components_v2/Issue/IssueBody/IssueBodyCompany/issueBodyCompany.scss";
@forward "app/components_v2/Issue/IssueHistory/issueHistory.scss";
@forward "app/components_v2/Issue/IssueHistory/IssueHistoryReopen/issueHistoryReopen.scss";
@forward "app/components_v2/Issue/IssueHistory/IssueHistoryPill/issueHistoryPill.scss";
@forward "app/components_v2/Issue/IssueHistory/IssueHistoryPillList/issueHistoryPillList.scss";

// Sporadic Task Detail
@forward "app/components_v2/__modals/SporadicTaskDetailModal/sporadicTaskDetailModal.scss";

// AlertPill
@forward "app/components_v2/AlertPill/alertPill.scss";
@forward "app/components_v2/AlertPill/AlertPillHeader/alertPillHeader.scss";
@forward "app/components_v2/AlertPill/AlertPillBody/alertPillBody.scss";
@forward "app/components_v2/AlertPill/AlertPillFiles/alertPillFiles.scss";

// OnBoarding
@forward "app/pages/01-SEG/OnBoarding/OnBoardingSteps/onBoardingSteps.scss";
@forward "app/pages/01-SEG/OnBoarding/OnBoardingStepIcon/onBoardingStepIcon.scss";
@forward "app/pages/01-SEG/OnBoarding/OnBoardingModals/OnBoardingReviewStepsModal/onBoardingReviewStepsModal.scss";
@forward "app/pages/01-SEG/OnBoarding/OnBoardingStepsTitle/onBoardingStepsTitle.scss";
@forward "app/pages/01-SEG/OnBoarding/OnBoardingSubStep/onBoardingSubStep.scss";
@forward "app/pages/01-SEG/OnBoarding/OnBoardingSubStepItem/onBoardingSubStepItem.scss";

// Automatic Records
@forward "app/pages/04-SEN/AutomaticRecordGrid/automaticRecord.scss";
@forward "app/pages/04-SEN/AutomaticRecordGrid/components/MobileAutomaticRecord/mobileAutomaticRecord.scss";

// ChatBotGPT
@forward "app/components_v2/ChatBotGPT/chatBotGPT.scss";
@forward "app/components_v2/ChatBotGPT/ChatBotMessageText/chatBotMessageText.scss";
@forward "app/components_v2/ChatBotGPT/ChatBotMessageHeader/chatBotMessageHeader.scss";
@forward "app/components_v2/ChatBotGPT/ChatBotInput/chatBotInput.scss";
@forward "app/components_v2/ChatBotGPT/ChatBotReplyButtons/chatBotReplyButtons.scss";

// EditImage
@forward "app/components_v2/EditImage/editImage.scss";
@forward "app/components_v2/EditImage/EditImageMenu/editImageMenu.scss";
@forward "app/components_v2/EditImage/EditImageIcon/editImageIcon.scss";
@forward "app/components_v2/EditImage/EditImageFooter/editImageFooter.scss";
@forward "app/components_v2/EditImage/EditImageFooterIcon/editImageFooterIcon.scss";
@forward "app/components_v2/EditImage/EditImageFooterDraw/editImageFooterDraw.scss";
@forward "app/components_v2/EditImage/EditImageFooterForms/editImageFooterForms.scss";
@forward "app/components_v2/EditImage/EditImageAcceptDecline/editImageAcceptDecline.scss";
@forward "app/components_v2/EditImage/EditImageFooterCropper/editImageFooterCropper.scss";

// Avatar
@forward "app/components_v2/Avatar/avatar.scss";
@forward "app/components_v2/Avatar/AvatarName/avatarName.scss";
@forward "app/components_v2/Avatar/AvatarImage/avatarImage.scss";
@forward "app/components_v2/Avatar/AvatarInitials/avatarInitials.scss";
@forward "app/components_v2/AvatarStack/avatarStack.scss";
@forward "app/components_v2/Avatar/AvatarQr/avatarQr.scss";

// Avatar Color Picker
@forward "app/components_v2/AvatarColorPicker/avatarColorPicker.scss";

// WizardTask Components
@forward "app/components_v2/WizardTask/addTaskHourButtons/addTaskHourButtons.scss";
@forward "app/components_v2/WizardTask/DataToReportList/dataToReportItem.scss";
@forward "app/components_v2/WizardTask/DynamicFields/DynamicFieldItem/dynamicFieldItem.scss";
@forward "app/components_v2/WizardTask/DynamicFields/DynamicFieldItemDropdown/dynamicFieldItemDropdown.scss";
@forward "app/components_v2/WizardTask/DynamicFields/dynamicFields.scss";
@forward "app/components_v2/WizardTask/DynamicFields/DynamicFieldItemDropdownMultiple/dynamicFieldItemDropdownMultiple.scss";
@forward "app/components_v2/WizardTask/multiTaskHour/multiTaskHour.scss";
@forward "app/components_v2/WizardTask/SelectDataToReport/selectDataReport.scss";
@forward "app/components_v2/WizardTask/SelectDataToReport/SelectDataToReportAsset/selectDataToReportAsset.scss";
@forward "app/components_v2/WizardTask/TaskTemporalityPeriodical/taskTemporalityPeriodical.scss";
@forward "app/components_v2/WizardTask/TaskTemporalityOneDay/taskTemporalityOneDay.scss";
@forward "app/components_v2/WizardTask/TaskTemporalityDateRange/taskTemporalityDateRange.scss";
@forward "app/components_v2/WizardTask/TaskTemporalityDateRange/TaskTemporalityDateRangeCustom/taskTemporalityDateRangeCustom.scss";
@forward "app/components_v2/WizardTask/TaskTemporalityDateRange/TaskTemporalityDateRangeRepeatEvery/taskTemporalityDateRangeRepeatEvery.scss";
@forward "app/components_v2/WizardTask/TaskTemporalityDateRange/TaskTemporalityDateRangeWeekly/taskTemporalityDateRangeWeekly.scss";
@forward "app/components_v2/WizardTask/TaskTemporalityDateRange/TaskTemporalityDateRangeMonthly/taskTemporalityDateRangeMonthly.scss";

// Disableds EditTask
@forward "app/components_v2/TaskTemporalityDisableds/TaskTemporalitySporadicDisabled/taskTemporalitySporadicDisabled.scss";
@forward "app/components_v2/TaskTemporalityDisableds/TaskTemporalityOneDayDisabled/taskTemporalityOneDayDisabled.scss";
@forward "app/components_v2/TaskTemporalityDisableds/TaskTemporalityPeriodicalDisabled/taskTemporalityPeriodicalDisabled.scss";
@forward "app/components_v2/TaskTemporalityDisableds/TaskTemporalityTitleDisabled/taskTemporalityTitleDisabled.scss";
@forward "app/components_v2/TaskTemporalityDisableds/TaskTemporalityDateRangeDisabled/taskTemporalityDateRangeDisabled.scss";
@forward "app/components_v2/TaskTemporalityDisableds/TaskTemporalityDateRangeDisabled/TaskTemporalityDateRangeCustomDisabled/taskTemporalityDateRangeCustomDisabled.scss";
@forward "app/components_v2/TaskTemporalityDisableds/TaskTemporalityDateRangeDisabled/TaskTemporalityDateRangeWeeklyDisabled/taskTemporalityDateRangeWeeklyDisabled.scss";
@forward "app/components_v2/TaskTemporalityDisableds/TaskTemporalityDateRangeDisabled/TaskTemporalityDateRangeMonthlyDisabled/taskTemporalityDateRangeMonthlyDisabled.scss";

// 07-Doc
@forward "app/components_v2/Documental/DocumentalFolder/documentalFolder.scss";
@forward "app/components_v2/Documental/DocumentalFile/documentalFile.scss";
@forward "app/components_v2/Documental/DocumentalMoreOptions/documentalMoreOptions.scss";

* {
    font-family: "poppins";
}
