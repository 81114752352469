@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.header {
    height: 48px;
    background-color: c.$primary600;
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;

    &--transparent {
        @extend .header;
        background-color: transparent;
    }
    &--grayMode {
        filter: grayscale(100%);
    }

    &--primary {
        @extend .header;
        background-color: c.$primary600;
    }
    &--appcc {
        @extend .header;
        background-color: c.$header-bg-appcc;
    }

    &__scroll {
        &--primary {
            background-color: c.$header-bg;
        }
        &--appcc {
            background-color: c.$header-bg-appcc;
        }
        &--grayMode {
            background-color: #4b4b4b;
        }
    }

    &__logo {
        display: flex;
        &__image {
            height: 24px;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 12px;

        &__pageName {
            color: c.$baseWhite;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
        }
        &__right,
        &__left {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        &__right {
            gap: 8px;
            &__links {
                display: flex;
                align-items: center;
                gap: 4px;
            }
            &__icons {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }

        &__left {
            gap: 16px;
            &__habmurguerContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 32px;
                margin-left: -7px;
                cursor: pointer;
                border-radius: 6px;
                transition: background 200ms;
                &:hover {
                    background: rgba(255, 255, 255, 0.1);
                }
            }
            &__hamburguerTitle {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            &__hamburguerIcon {
                color: c.$baseWhite;
                cursor: pointer;
                font-weight: 400;
                font-size: 20px;
                line-height: 20px;
            }
        }
    }
}
.marginTop18 {
    margin-top: 18px;
}
@media screen and (min-width: v.$tablet) {
    .header {
        &--grayMode {
            filter: none;
        }
        &__content {
            padding: 0 20px;

            &__right {
                gap: 20px;

                &__links {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                }
            }
            &__left {
                gap: 32px;

                &__links {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                }
                &__icons {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
            }
        }
    }
}

@media screen and (min-width: v.$laptop) {
    .header {
        &__content {
            &__right {
                &__links__bell {
                    display: none;
                }
            }
            &__left__links {
                gap: 4px;
            }
        }
    }
}

.headerNotifications {
    min-width: 350px;
    background-color: white;
    transform: translate(-50%, 0) !important;
    top: 50px !important;
    left: 50%;
    &__popover {
        @extend .headerNotifications;
        min-width: 250px;
        width: 250px;

        &--height225 {
            @extend .headerNotifications__popover;
            height: auto !important;
            min-width: 270px;
            border-radius: 12px;
            overflow: hidden;
            border: 1px solid c.$gray300;
            background: c.$baseWhite;
            box-shadow: c.$shadow-md;
        }
    }

    &__notificationPopover {
        @extend .headerNotifications;
        max-width: 350px;
        height: auto !important;
        border-radius: 12px;
        border: 1px solid c.$gray200;
        background: c.$baseWhite;
        box-shadow: c.$shadow-lg;
    }

    &__content {
        height: 450px;
    }
}

.headerNotificationContainer {
    width: 375px;
    max-width: 375px;
    min-height: 375px;
    max-height: 650px;
    height: 60vh;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}
