@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;
@use "/src/styles/variables.scss" as v;

.documentalFolderModal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    &__title {
        color: c.$primary500;
        @include t.text("sm", "bold");
    }
}

@media screen and (min-width: v.$tablet) {
    .documentalFolderModal {
        gap: 32px;
    }
}
