@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.tag {
    display: inline-flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: fit-content;
    max-width: 100%;
    cursor: default;
    &--xs {
        @extend .tag;
        padding: 6px 8px;
        gap: 8px;
        height: 32px;
    }
    &--md {
        @extend .tag;
        padding: 10px 14px 10px 10px;
        gap: 10px;
        height: 48px;
    }
    &--auto {
        @extend .tag;
        padding: 6px 8px;
        gap: 8px;
        height: auto;
    }
    &--primary {
        @extend .tag;
        border: 1px solid c.$primary200;
        background: c.$primary50;
    }
    &--primaryReadonly {
        @extend .tag;
        background-color: c.$primary25;
    }
    &--green {
        @extend .tag;
        border: 1px solid c.$success600;
        background: c.$success50;
        color: c.$success600;
        @include t.text("xs", "semibold");
    }
    &--red {
        @extend .tag;
        border: 1px solid c.$error600;
        background: c.$error50;
        color: c.$error600;
        @include t.text("xs", "semibold");
    }
    &--white {
        @extend .tag;
        border: 1px solid c.$gray300;
        background: c.$baseWhite;
    }
    &--gray {
        @extend .tag;
        border: 1px solid c.$gray300;
        background: c.$gray50;
    }

    &--other {
        @extend .tag;
        border-radius: 0px 8px 8px 8px;
    }
    &--mine {
        @extend .tag;
        border-radius: 8px 0px 8px 8px;
    }

    &__text {
        @include t.text("sm", "medium");
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        &--primary {
            @extend .tag__text;
            color: c.$primary600;
        }
        &--primaryReadonly {
            @include t.text("sm", "medium");
            color: c.$gray900;
        }
        &--white {
            @extend .tag__text;
            color: c.$gray700;
        }
        &--gray {
            @extend .tag__text;
            color: c.$gray700;
        }
    }
    &__subtitle {
        @include t.text("xs");
        color: c.$gray600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        overflow: hidden;
        word-break: break-word;
    }

    &__icon {
        font-size: 16px;
        &--primary {
            @extend .tag__icon;
            color: c.$primary600;
        }
        &--white {
            @extend .tag__icon;
            color: c.$gray500;
        }
        &--gray {
            @extend .tag__icon;
            color: c.$gray500;
        }
    }
}
