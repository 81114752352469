@use "src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.printQr {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    &__content {
        margin-top: 20px;
        height: fit-content;
        border: 1px solid c.$gray200;
        box-shadow: v.$shadow-light;
        padding: 12px;
        border-radius: 8px;
    }
}
