@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.checkboxButton2 {
    border-radius: 2px;
    border-width: 1.5px;
    border-color: c.$gray300;
    background-color: c.$baseWhite;
    border-style: solid;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms;
    &--md,
    &--sm {
        width: 20px;
        min-width: 20px;
        height: 20px;
        min-height: 20px;
        &.readonly {
            cursor: default;
        }
    }
    &--xl {
        width: 32px;
        min-width: 32px;
        height: 32px;
        min-height: 32px;
        &.readonly {
            cursor: default;
        }
    }

    &--rounded {
        @extend .checkboxButton2;
        border-radius: 50%;
        overflow: hidden;
        &:hover {
            @extend .checkboxButton2;
            border-radius: 50%;
            overflow: hidden;
        }
        &.disabled {
            @extend .checkboxButton2--rounded;
            &:hover {
                @extend .checkboxButton2--rounded;
                border-radius: 50%;
                overflow: hidden;
            }
        }
    }
    &--light {
        &--primary {
            @extend .checkboxButton2;

            &:hover {
                border-color: c.$primary100;
                background-color: c.$primary100;
            }

            &--focus {
                @extend .checkboxButton2;
                border-color: c.$primary100;
                background-color: c.$primary100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$primary100;
                    background-color: c.$primary100;
                }
            }

            &--active {
                @extend .checkboxButton2;
                border-color: c.$primary500;
                background-color: c.$primary100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$primary100;
                    background-color: c.$primary100;
                }
                &.disabled {
                    @extend .checkboxButton2;
                    background-color: c.$gray300;
                    background-color: c.$gray300;
                    cursor: default !important;
                    &:hover {
                        @extend .checkboxButton2;
                        background-color: c.$gray300;
                        background-color: c.$gray300;
                    }
                }
                &--focus {
                    @extend .checkboxButton2;
                    border-color: c.$primary200;
                    background-color: c.$primary200;
                    &:hover {
                        @extend .checkboxButton2;
                        border-color: c.$primary200;
                        background-color: c.$primary200;
                    }
                }
            }

            &__interior {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                color: c.$gray300;
                &--checked {
                    @extend .checkboxButton2--light--primary__interior;
                    color: c.$primary500;
                }
            }

            &.disabled {
                @extend .checkboxButton2;
                background-color: c.$gray100;
                border-color: c.$gray300;
                cursor: default !important;

                &:hover {
                    background-color: c.$gray100;
                    border-color: c.$gray300;
                }
            }
        }
        &--green {
            @extend .checkboxButton2;

            &--focus {
                @extend .checkboxButton2;
                border-color: c.$success100;
                background-color: c.$success100;
            }

            &--active {
                @extend .checkboxButton2;
                border-color: c.$success500;
                background-color: c.$success100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$success100;
                    background-color: c.$success100;
                }
                &.disabled {
                    @extend .checkboxButton2;
                    background-color: c.$gray300;
                    background-color: c.$gray300;
                    cursor: default !important;
                    &:hover {
                        @extend .checkboxButton2;
                        background-color: c.$gray300;
                        background-color: c.$gray300;
                    }
                }
                &--focus {
                    @extend .checkboxButton2;
                    border-color: c.$success200;
                    background-color: c.$success200;
                    &:hover {
                        @extend .checkboxButton2;
                        border-color: c.$success200;
                        background-color: c.$success200;
                    }
                }
            }

            &__interior {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                color: c.$gray300;
                &--checked {
                    @extend .checkboxButton2--light--green__interior;
                    color: c.$success500;
                }
            }

            &.disabled {
                @extend .checkboxButton2;
                background-color: c.$gray100;
                border-color: c.$gray300;
                cursor: default !important;

                &:hover {
                    background-color: c.$gray100;
                    border-color: c.$gray300;
                }
            }
        }
        &--orange {
            @extend .checkboxButton2;

            &--focus {
                @extend .checkboxButton2;
                border-color: c.$warning100;
                background-color: c.$warning100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$warning100;
                    background-color: c.$warning100;
                }
            }

            &--active {
                @extend .checkboxButton2;
                border-color: c.$warning500;
                background-color: c.$warning100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$warning100;
                    background-color: c.$warning100;
                }
                &.disabled {
                    @extend .checkboxButton2;
                    background-color: c.$gray300;
                    background-color: c.$gray300;
                    cursor: default !important;
                    &:hover {
                        @extend .checkboxButton2;
                        background-color: c.$gray300;
                        background-color: c.$gray300;
                    }
                }
                &--focus {
                    @extend .checkboxButton2;
                    border-color: c.$warning200;
                    background-color: c.$warning200;
                    &:hover {
                        @extend .checkboxButton2;
                        border-color: c.$warning200;
                        background-color: c.$warning200;
                    }
                }
            }

            &__interior {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                color: c.$gray300;
                &--checked {
                    @extend .checkboxButton2--light--orange__interior;
                    color: c.$warning500;
                }
            }

            &.disabled {
                @extend .checkboxButton2;
                background-color: c.$gray100;
                border-color: c.$gray300;
                cursor: default !important;

                &:hover {
                    background-color: c.$gray100;
                    border-color: c.$gray300;
                }
            }
        }
        &--red {
            @extend .checkboxButton2;

            &--focus {
                @extend .checkboxButton2;
                border-color: c.$error100;
                background-color: c.$error100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$error100;
                    background-color: c.$error100;
                }
            }

            &--active {
                @extend .checkboxButton2;
                border-color: c.$error500;
                background-color: c.$error100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$error100;
                    background-color: c.$error100;
                }
                &.disabled {
                    @extend .checkboxButton2;
                    background-color: c.$gray300;
                    background-color: c.$gray300;
                    cursor: default !important;
                    &:hover {
                        @extend .checkboxButton2;
                        background-color: c.$gray300;
                        background-color: c.$gray300;
                    }
                }
                &--focus {
                    @extend .checkboxButton2;
                    border-color: c.$error200;
                    background-color: c.$error200;
                    &:hover {
                        @extend .checkboxButton2;
                        border-color: c.$error200;
                        background-color: c.$error200;
                    }
                }
            }

            &__interior {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                color: c.$gray300;
                &--checked {
                    @extend .checkboxButton2--light--green__interior;
                    color: c.$error500;
                }
            }

            &.disabled {
                @extend .checkboxButton2;
                background-color: c.$gray100;
                border-color: c.$gray300;
                cursor: default !important;

                &:hover {
                    background-color: c.$gray100;
                    border-color: c.$gray300;
                }
            }
        }
        &--gray {
            @extend .checkboxButton2;

            &--focus {
                @extend .checkboxButton2;
                border-color: c.$gray100;
                background-color: c.$gray100;
            }

            &--active {
                @extend .checkboxButton2;
                border-color: c.$gray500;
                background-color: c.$gray100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$gray100;
                    background-color: c.$gray100;
                }
                &.disabled {
                    @extend .checkboxButton2;
                    background-color: c.$gray300;
                    background-color: c.$gray300;
                    cursor: default !important;
                    &:hover {
                        @extend .checkboxButton2;
                        background-color: c.$gray300;
                        background-color: c.$gray300;
                    }
                }
                &--focus {
                    @extend .checkboxButton2;
                    border-color: c.$gray200;
                    background-color: c.$gray200;
                    &:hover {
                        @extend .checkboxButton2;
                        border-color: c.$gray200;
                        background-color: c.$gray200;
                    }
                }
            }

            &__interior {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                color: c.$gray300;
                &--checked {
                    @extend .checkboxButton2--light--gray__interior;
                    color: c.$gray500;
                }
            }

            &.disabled {
                @extend .checkboxButton2;
                background-color: c.$gray100;
                border-color: c.$gray300;
                cursor: default !important;

                &:hover {
                    background-color: c.$gray100;
                    border-color: c.$gray300;
                }
            }
        }
    }
    &--dark {
        &--primary {
            @extend .checkboxButton2;

            &--focus {
                @extend .checkboxButton2;
                border-color: c.$primary600;
                background-color: c.$primary100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$primary600;
                    background-color: c.$primary100;
                }
            }

            &--active {
                @extend .checkboxButton2;
                border-color: c.$primary600;
                background-color: c.$primary600;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$primary600;
                    background-color: c.$primary600;
                }
                &.disabled {
                    @extend .checkboxButton2;
                    background-color: c.$gray300;
                    background-color: c.$gray300;
                    cursor: default !important;
                    &:hover {
                        @extend .checkboxButton2;
                        background-color: c.$gray300;
                        background-color: c.$gray300;
                    }
                }
                &--focus {
                    @extend .checkboxButton2;
                    border-color: c.$primary800;
                    background-color: c.$primary800;
                    &:hover {
                        @extend .checkboxButton2;
                        border-color: c.$primary800;
                        background-color: c.$primary800;
                    }
                }
            }

            &__interior {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                &--checked {
                    @extend .checkboxButton2--dark--primary__interior;
                    color: c.$baseWhite;
                }
            }

            &.disabled {
                @extend .checkboxButton2;
                background-color: c.$gray100;
                border-color: c.$gray300;
                cursor: default !important;

                &:hover {
                    background-color: c.$gray100;
                    border-color: c.$gray300;
                }
            }
        }
        &--green {
            @extend .checkboxButton2;

            &--focus {
                @extend .checkboxButton2;
                border-color: c.$success600;
                background-color: c.$success100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$success600;
                    background-color: c.$success100;
                }
            }

            &--active {
                @extend .checkboxButton2;
                border-color: c.$success600;
                background-color: c.$success600;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$success600;
                    background-color: c.$success600;
                }
                &.disabled {
                    @extend .checkboxButton2;
                    background-color: c.$gray300;
                    background-color: c.$gray300;
                    cursor: default !important;
                    &:hover {
                        @extend .checkboxButton2;
                        background-color: c.$gray300;
                        background-color: c.$gray300;
                    }
                }
                &--focus {
                    @extend .checkboxButton2;
                    border-color: c.$success800;
                    background-color: c.$success800;
                    &:hover {
                        @extend .checkboxButton2;
                        border-color: c.$success800;
                        background-color: c.$success800;
                    }
                }
            }

            &__interior {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                &--checked {
                    @extend .checkboxButton2--dark--green__interior;
                    color: c.$baseWhite;
                }
            }

            &.disabled {
                @extend .checkboxButton2;
                background-color: c.$gray100;
                border-color: c.$gray300;
                cursor: default !important;

                &:hover {
                    background-color: c.$gray100;
                    border-color: c.$gray300;
                }
            }
        }
        &--orange {
            @extend .checkboxButton2;

            &--focus {
                @extend .checkboxButton2;
                border-color: c.$warning600;
                background-color: c.$warning100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$warning600;
                    background-color: c.$warning100;
                }
            }

            &--active {
                @extend .checkboxButton2;
                border-color: c.$warning600;
                background-color: c.$warning600;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$warning600;
                    background-color: c.$warning600;
                }
                &.disabled {
                    @extend .checkboxButton2;
                    background-color: c.$gray300;
                    background-color: c.$gray300;
                    cursor: default !important;
                    &:hover {
                        @extend .checkboxButton2;
                        background-color: c.$gray300;
                        background-color: c.$gray300;
                    }
                }
                &--focus {
                    @extend .checkboxButton2;
                    border-color: c.$warning800;
                    background-color: c.$warning800;
                    &:hover {
                        @extend .checkboxButton2;
                        border-color: c.$warning800;
                        background-color: c.$warning800;
                    }
                }
            }

            &__interior {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                &--checked {
                    @extend .checkboxButton2--dark--orange__interior;
                    color: c.$baseWhite;
                }
            }

            &.disabled {
                @extend .checkboxButton2;
                background-color: c.$gray100;
                border-color: c.$gray300;
                cursor: default !important;

                &:hover {
                    background-color: c.$gray100;
                    border-color: c.$gray300;
                }
            }
        }
        &--red {
            @extend .checkboxButton2;

            &--focus {
                @extend .checkboxButton2;
                border-color: c.$error600;
                background-color: c.$error100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$error600;
                    background-color: c.$error100;
                }
            }

            &--active {
                @extend .checkboxButton2;
                border-color: c.$error600;
                background-color: c.$error600;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$error600;
                    background-color: c.$error600;
                }
                &.disabled {
                    @extend .checkboxButton2;
                    background-color: c.$gray300;
                    background-color: c.$gray300;
                    cursor: default !important;
                    &:hover {
                        @extend .checkboxButton2;
                        background-color: c.$gray300;
                        background-color: c.$gray300;
                    }
                }
                &--focus {
                    @extend .checkboxButton2;
                    border-color: c.$error800;
                    background-color: c.$error800;
                    &:hover {
                        @extend .checkboxButton2;
                        border-color: c.$error800;
                        background-color: c.$error800;
                    }
                }
            }

            &__interior {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                &--checked {
                    @extend .checkboxButton2--dark--green__interior;
                    color: c.$baseWhite;
                }
            }

            &.disabled {
                @extend .checkboxButton2;
                background-color: c.$gray100;
                border-color: c.$gray300;
                cursor: default !important;

                &:hover {
                    background-color: c.$gray100;
                    border-color: c.$gray300;
                }
            }
        }
        &--gray {
            @extend .checkboxButton2;

            &--focus {
                @extend .checkboxButton2;
                border-color: c.$gray600;
                background-color: c.$gray100;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$gray600;
                    background-color: c.$gray100;
                }
            }

            &--active {
                @extend .checkboxButton2;
                border-color: c.$gray600;
                background-color: c.$gray600;
                &:hover {
                    @extend .checkboxButton2;
                    border-color: c.$gray600;
                    background-color: c.$gray600;
                }
                &.disabled {
                    @extend .checkboxButton2;
                    background-color: c.$gray300;
                    background-color: c.$gray300;
                    cursor: default !important;
                    &:hover {
                        @extend .checkboxButton2;
                        background-color: c.$gray300;
                        background-color: c.$gray300;
                    }
                }
                &--focus {
                    @extend .checkboxButton2;
                    border-color: c.$gray800;
                    background-color: c.$gray800;
                    &:hover {
                        @extend .checkboxButton2;
                        border-color: c.$gray800;
                        background-color: c.$gray800;
                    }
                }
            }

            &__interior {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                &--checked {
                    @extend .checkboxButton2--dark--gray__interior;
                    color: c.$baseWhite;
                }
            }

            &.disabled {
                @extend .checkboxButton2;
                background-color: c.$gray100;
                border-color: c.$gray300;
                cursor: default !important;

                &:hover {
                    background-color: c.$gray100;
                    border-color: c.$gray300;
                }
            }
        }
    }

    &__interior {
        &--md,
        &--sm {
            font-size: 12px;
            & svg {
                width: 12px;
            }
        }
        &--xl {
            font-size: 16px;
            & svg {
                width: 16px;
            }
        }
    }

    &__wrapper {
        display: flex;
        gap: 12px;
        align-items: center;
        flex-direction: row;
        &--reverse {
            @extend .checkboxButton2__wrapper;
            flex-direction: row-reverse;
        }
    }
}

@media screen and (min-width: v.$tablet) {
    .checkboxButton2 {
        &--light {
            &--primary {
                @extend .checkboxButton2;

                &:hover {
                    border-color: c.$primary100;
                    background-color: c.$primary100;
                    &.readonly {
                        border-color: c.$gray300;
                        background-color: c.$baseWhite;
                    }
                }

                &--focus {
                    &:hover {
                        border-color: c.$primary100;
                        background-color: c.$primary100;
                    }
                }

                &--active {
                    &:hover {
                        border-color: c.$primary200;
                        background-color: c.$primary200;
                    }
                    &.readonly {
                        &:hover {
                            border-color: c.$primary100;
                            background-color: c.$primary100;
                        }
                    }

                    &.disabled {
                        &:hover {
                            background-color: c.$gray300;
                            background-color: c.$gray300;
                        }
                    }
                    &--focus {
                        &:hover {
                            border-color: c.$primary200;
                            background-color: c.$primary200;
                        }
                    }
                }

                &.disabled {
                    &:hover {
                        background-color: c.$gray100;
                        border-color: c.$gray300;
                    }
                }
            }
            &--green {
                &:hover {
                    border-color: c.$success100;
                    background-color: c.$success100;
                    &.readonly {
                        border-color: c.$gray300;
                        background-color: c.$baseWhite;
                    }
                }
                &--focus {
                    &:hover {
                        border-color: c.$success100;
                        background-color: c.$success100;
                    }
                }

                &--active {
                    &:hover {
                        border-color: c.$success200;
                        background-color: c.$success200;
                    }
                    &.readonly {
                        &:hover {
                            border-color: c.$success100;
                            background-color: c.$success100;
                        }
                    }
                    &.disabled {
                        &:hover {
                            background-color: c.$gray300;
                            background-color: c.$gray300;
                        }
                    }
                    &--focus {
                        &:hover {
                            border-color: c.$success200;
                            background-color: c.$success200;
                        }
                    }
                }

                &.disabled {
                    &:hover {
                        background-color: c.$gray100;
                        border-color: c.$gray300;
                    }
                }
            }
            &--orange {
                &:hover {
                    border-color: c.$warning100;
                    background-color: c.$warning100;
                    &.readonly {
                        border-color: c.$gray300;
                        background-color: c.$baseWhite;
                    }
                }

                &--focus {
                    &:hover {
                        @extend .checkboxButton2;
                        border-color: c.$warning100;
                        background-color: c.$warning100;
                    }
                }

                &--active {
                    &:hover {
                        border-color: c.$warning200;
                        background-color: c.$warning200;
                        &.readonly {
                            &:hover {
                                border-color: c.$warning100;
                                background-color: c.$warning100;
                            }
                        }
                    }
                    &.disabled {
                        &:hover {
                            background-color: c.$gray300;
                            background-color: c.$gray300;
                        }
                    }
                    &--focus {
                        &:hover {
                            border-color: c.$warning200;
                            background-color: c.$warning200;
                        }
                    }
                }

                &.disabled {
                    &:hover {
                        background-color: c.$gray100;
                        border-color: c.$gray300;
                    }
                }
            }
            &--red {
                &:hover {
                    border-color: c.$error100;
                    background-color: c.$error100;
                    &.readonly {
                        border-color: c.$gray300;
                        background-color: c.$baseWhite;
                    }
                }
                &--focus {
                    &:hover {
                        border-color: c.$error100;
                        background-color: c.$error100;
                    }
                }
                &--active {
                    &:hover {
                        border-color: c.$error200;
                        background-color: c.$error200;
                        &.readonly {
                            &:hover {
                                border-color: c.$error100;
                                background-color: c.$error100;
                            }
                        }
                    }
                    &.disabled {
                        &:hover {
                            background-color: c.$gray300;
                            background-color: c.$gray300;
                        }
                    }
                    &--focus {
                        &:hover {
                            border-color: c.$error200;
                            background-color: c.$error200;
                        }
                    }
                }

                &.disabled {
                    &:hover {
                        background-color: c.$gray100;
                        border-color: c.$gray300;
                    }
                }
            }
            &--gray {
                &:hover {
                    border-color: c.$gray100;
                    background-color: c.$gray100;
                    &.readonly {
                        border-color: c.$gray300;
                        background-color: c.$baseWhite;
                    }
                }
                &--focus {
                    &:hover {
                        border-color: c.$gray100;
                        background-color: c.$gray100;
                    }
                }

                &--active {
                    &:hover {
                        border-color: c.$gray200;
                        background-color: c.$gray200;
                        &.readonly {
                            &:hover {
                                border-color: c.$gray100;
                                background-color: c.$gray100;
                            }
                        }
                    }
                    &.disabled {
                        &:hover {
                            background-color: c.$gray300;
                            background-color: c.$gray300;
                        }
                    }
                    &--focus {
                        &:hover {
                            border-color: c.$gray200;
                            background-color: c.$gray200;
                        }
                    }
                }

                &.disabled {
                    &:hover {
                        background-color: c.$gray100;
                        border-color: c.$gray300;
                    }
                }
            }
        }
        &--dark {
            &--primary {
                &:hover {
                    border-color: c.$primary600;
                    background-color: c.$primary100;
                    &.readonly {
                        border-color: c.$gray300;
                        background-color: c.$baseWhite;
                    }
                }

                &--focus {
                    &:hover {
                        border-color: c.$primary600;
                        background-color: c.$primary100;
                    }
                }

                &--active {
                    &:hover {
                        border-color: c.$primary700;
                        background-color: c.$primary700;
                        &.readonly {
                            &:hover {
                                border-color: c.$primary600;
                                background-color: c.$primary600;
                            }
                        }
                    }
                    &.disabled {
                        &:hover {
                            background-color: c.$gray300;
                            background-color: c.$gray300;
                        }
                    }
                    &--focus {
                        &:hover {
                            border-color: c.$primary800;
                            background-color: c.$primary800;
                        }
                    }
                }

                &.disabled {
                    &:hover {
                        background-color: c.$gray100;
                        border-color: c.$gray300;
                    }
                }
            }
            &--green {
                &:hover {
                    border-color: c.$success600;
                    background-color: c.$success100;
                    &.readonly {
                        border-color: c.$gray300;
                        background-color: c.$baseWhite;
                    }
                }

                &--focus {
                    &:hover {
                        border-color: c.$success600;
                        background-color: c.$success100;
                    }
                }

                &--active {
                    &:hover {
                        border-color: c.$success700;
                        background-color: c.$success700;
                        &.readonly {
                            &:hover {
                                border-color: c.$success600;
                                background-color: c.$success600;
                            }
                        }
                    }
                    &.disabled {
                        &:hover {
                            background-color: c.$gray300;
                            background-color: c.$gray300;
                        }
                    }
                    &--focus {
                        &:hover {
                            border-color: c.$success800;
                            background-color: c.$success800;
                        }
                    }
                }

                &.disabled {
                    &:hover {
                        background-color: c.$gray100;
                        border-color: c.$gray300;
                    }
                }
            }
            &--orange {
                &:hover {
                    border-color: c.$warning600;
                    background-color: c.$warning100;
                    &.readonly {
                        border-color: c.$gray300;
                        background-color: c.$baseWhite;
                    }
                }

                &--focus {
                    &:hover {
                        border-color: c.$warning600;
                        background-color: c.$warning100;
                    }
                }

                &--active {
                    &:hover {
                        border-color: c.$warning700;
                        background-color: c.$warning700;
                        &.readonly {
                            &:hover {
                                border-color: c.$warning600;
                                background-color: c.$warning600;
                            }
                        }
                    }
                    &.disabled {
                        &:hover {
                            background-color: c.$gray300;
                            background-color: c.$gray300;
                        }
                    }
                    &--focus {
                        &:hover {
                            border-color: c.$warning800;
                            background-color: c.$warning800;
                        }
                    }
                }

                &.disabled {
                    &:hover {
                        background-color: c.$gray100;
                        border-color: c.$gray300;
                    }
                }
            }
            &--red {
                &:hover {
                    border-color: c.$error600;
                    background-color: c.$error100;
                    &.readonly {
                        border-color: c.$gray300;
                        background-color: c.$baseWhite;
                    }
                }

                &--focus {
                    &:hover {
                        border-color: c.$error600;
                        background-color: c.$error100;
                    }
                }

                &--active {
                    &:hover {
                        border-color: c.$error700;
                        background-color: c.$error700;
                        &.readonly {
                            &:hover {
                                border-color: c.$error600;
                                background-color: c.$error600;
                            }
                        }
                    }
                    &.disabled {
                        &:hover {
                            background-color: c.$gray300;
                            background-color: c.$gray300;
                        }
                    }
                    &--focus {
                        &:hover {
                            border-color: c.$error800;
                            background-color: c.$error800;
                        }
                    }
                }

                &.disabled {
                    &:hover {
                        background-color: c.$gray100;
                        border-color: c.$gray300;
                    }
                }
            }
            &--gray {
                &:hover {
                    border-color: c.$gray600;
                    background-color: c.$gray100;
                    &.readonly {
                        border-color: c.$gray300;
                        background-color: c.$baseWhite;
                    }
                }

                &--focus {
                    &:hover {
                        border-color: c.$gray600;
                        background-color: c.$gray100;
                    }
                }

                &--active {
                    &:hover {
                        border-color: c.$gray700;
                        background-color: c.$gray700;
                        &.readonly {
                            &:hover {
                                border-color: c.$gray600;
                                background-color: c.$gray600;
                            }
                        }
                    }
                    &.disabled {
                        &:hover {
                            background-color: c.$gray300;
                            background-color: c.$gray300;
                        }
                    }
                    &--focus {
                        &:hover {
                            border-color: c.$gray800;
                            background-color: c.$gray800;
                        }
                    }
                }

                &.disabled {
                    &:hover {
                        background-color: c.$gray100;
                        border-color: c.$gray300;
                    }
                }
            }
        }
    }
}
