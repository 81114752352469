@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.optionsWithAvatar {
    display: flex;
    gap: 8px;
    align-items: center;
    &__info {
        display: flex;
        flex-direction: column;
    }
    &__label {
        color: hsl(0, 0%, 20%);
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        @include t.text("sm", "medium");
        &--selected {
            color: c.$baseWhite;
        }
    }
    &__role {
        color: c.$gray600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        overflow: hidden;
        word-break: break-word;
        @include t.text("xs");
        &--selected {
            color: c.$baseWhite;
        }
    }
}
