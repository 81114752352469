@use "/src/styles/variables.scss" as v;
@use "/src/styles/colors.scss" as c;
@use "/src/styles/typographies.scss" as t;

.documentalFolderPageHeader {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    &__header {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
    }
    &__title {
        color: c.$baseWhite;
        text-align: left;
        @include t.text("xl", "semibold");
    }
    &__search {
        width: 100%;
    }
    &__filters {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }
    &__orderBy {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: flex-end;
        cursor: pointer;
        height: 100%;
        flex-shrink: 0;
        border-radius: 4px;
        padding: 4px;

        &:hover {
            background-color: c.$iconHoverBg;
        }
        &__text {
            color: c.$baseWhite;
            user-select: none;
            @include t.text("xs", "medium");
        }
        &__container {
            display: flex;
            gap: 2px;
        }
    }
}

@media screen and (min-width: v.$mobile) {
    .documentalFolderPageHeader {
        &__header {
            flex-direction: row;
        }
        &__search {
            width: 250px;
            min-width: 250px;
        }
    }
}
