@use "/src/styles/colors.scss" as c;
@use "/src/styles/variables.scss" as v;

.genericModal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: c.$blackTransparent;
    &--noBlur {
        background-color: transparent;
    }
    &--md {
        padding: 0 12px;
    }
    &--sm {
        align-items: center;
        padding: 0 12px;
    }

    &__container {
        background-color: c.$baseWhite;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        &--sm {
            height: auto;
            max-height: 80%;
            max-width: 450px;
            width: 100%;
            border-radius: 12px;
        }
        &--md {
            width: 100%;
            max-width: 560px;
            height: auto;
            max-height: 80%;
            border-radius: 10px;
        }
        &--md-tall {
            @extend .genericModal__container--md;
            height: 100%;
            max-height: 100%;
            width: 100%;
            border-radius: 0px;
            max-width: 100%;
        }
        &--lg,
        &--xl {
            height: 100%;
            width: 100%;
        }
        &--xl {
            max-width: 1200px;
        }
        &--fit-content {
            height: auto;
            max-height: 80%;
            min-width: 330px;
            width: fit-content;
            border-radius: 12px;
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 0 0 12px 12px;
        &__children {
            flex: 1;
            overflow: auto;
            padding: 12px;
            &--sm {
                padding: 20px;
            }
            &--noPadding {
                padding: 0px;
            }
        }
    }
    &__footer {
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-top: 1px solid c.$gray200;
        background-color: c.$baseWhite;
    }
}

@media screen and (min-width: v.$tablet) {
    .genericModal {
        &--md-tall {
            padding: 20px 0px;
        }
        &__container {
            &--md-tall {
                max-width: 560px;
                border-radius: 12px;
                height: 90%;
                max-height: 90%;
            }
            &--lg,
            &--xl {
                max-height: 80%;
                border-radius: 12px;
                width: 90%;
            }
            &--lg {
                max-width: 800px;
            }
        }
        &__body {
            &__children {
                padding: 32px;
                &--noPadding {
                    padding: 0px;
                }
            }
        }
    }
}
